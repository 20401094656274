import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit {

  @Input() user;

  time = 0;
  phone = '';
  msg1 = '';
  msg2 = '';
  msg3 = '';
  msg4 = '';
  msg5 = '';
  msg6 = '';

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    // console.log(this.user);

    this.time = this.user.booking_confirmation_time ? this.user.booking_confirmation_time : 0;
    this.phone = this.user.booking_confirmation_phone ? this.user.booking_confirmation_phone : '';
    this.msg1 = this.user.booking_cancellation_msg1 ? this.user.booking_cancellation_msg1 : '';
    this.msg2 = this.user.booking_cancellation_msg2 ? this.user.booking_cancellation_msg2 : '';
    this.msg3 = this.user.booking_cancellation_msg3 ? this.user.booking_cancellation_msg3 : '';
    this.msg4 = this.user.booking_cancellation_msg4 ? this.user.booking_cancellation_msg4 : '';
    this.msg5 = this.user.booking_cancellation_msg5 ? this.user.booking_cancellation_msg5 : '';
    this.msg6 = this.user.booking_cancellation_msg6 ? this.user.booking_cancellation_msg6 : '';
    console.log({ time: this.time, phone: this.phone, msg1: this.msg1, msg2: this.msg2 });

  }

  saveValue() {
    this.modal.close({
      time: this.time,
      phone: this.phone,
      msg1: this.msg1,
      msg2: this.msg2,
      msg3: this.msg3,
      msg4: this.msg4,
      msg5: this.msg5,
      msg6: this.msg6
    })
  }

}
