import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BotService, AdminService } from './../../services';

import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {


  public loginForm: FormGroup;
  isLoading: boolean;
  passwordErr: boolean;
  errMsg = '';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private AS: AdminService
  ) { }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('SMSADM'));
    if (currentUser) {
      this.router.navigateByUrl('/admin/users');
    }
    this.loginForm = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  submit() {
    this.isLoading = true;
    this.errMsg = '';
    let obj = {
      user_name: this.loginForm.value.user_name,
      password: this.loginForm.value.password
    }
    this.AS.adminSignin(obj).subscribe((cred: any) => {
      localStorage.setItem('SMSADMID', cred.token);
      this.AS.getCurrentAdminFromBack().subscribe(user => {
        this.isLoading = false;
        this.router.navigateByUrl('/admin/users');
      }, err => {
        this.isLoading = false;
        this.errMsg = err.error.message;
      })
    }, err => {
      this.isLoading = false;
      this.errMsg = err.error.message;
      if (this.errMsg) {
        this.passwordErr = true
      }

    })
  }

}
