import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from './../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component'

import * as XLSX from 'xlsx';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss']
}

) export class BroadcastComponent implements OnInit {

  private serviceModal: NgbModalRef;
  name = '';
  delay = 0;
  column = 1;
  messageBody = '';
  showHoverText = false;
  excelHeaders: string[] = [];
  excelData: any[] = [];
  adminDetails: any;
  isLoad = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _serviceModal: NgbModal,
    private AS: AdminService,
    private BS: BotService
  ) { }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.AS.getUser(currentUser._id).subscribe(admin => {
      this.adminDetails = admin;
    })
  }
  // onFileChange(event: any): void {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const data = e.target?.result;
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const sheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[sheetName];
  //     const excelData = XLSX.utils.sheet_to_json(worksheet);

  //     console.log(excelData);
  //   };

  //   reader.readAsArrayBuffer(file);
  // }
  onFileChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;

      const workbook = XLSX.read(data, {
        type: 'array'
      }

      );
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      this.excelData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1
      }
      );

      if (this.excelData.length > 0) {
        console.log(this.excelData);
        this.excelHeaders = this.excelData[0];

        this.excelData.splice(0, 1); // Remove the header row
      }
    };

    reader.readAsArrayBuffer(file);
  }

  setBroadcast() {
    let contacts = [];
    if (this.delay >= 0 && this.column > 0) {
      this.excelData.map(rl => {
        let number = rl[this.column - 1].toString()
        number.replace('+', '')
        if (number.startsWith('7')) { number = '44' + number; }
        if (number.startsWith('07')) { number = number.slice(1); number = '44' + number; }
        if (number.startsWith('00447')) { number = number.slice(2); }
        if (number.startsWith('447')) { contacts.push(number) }
      })
      let obj = {
        company_id: this.adminDetails._id,
        broadcast_name: this.name,
        message_body: this.messageBody,
        delay_time: this.delay,
        contacts: contacts
      }
      // console.log(obj);

      this.BS.setBroadcast(obj).subscribe(res => {
        this.name = ''
        this.messageBody = '';
        this.delay = 0;
        this.column = 1;
        this.isLoad = true;
        setTimeout(() => { this.isLoad = false }, 7000)
      })

    } else {
      alert("Set delay and Contact column.")
    }
  }

  showText() {
    this.showHoverText = true;
  }

  hideText() {
    this.showHoverText = false;
  }

}