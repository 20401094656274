import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPhone'
})
export class SearchPhonePipe implements PipeTransform {

  transform(value: any, phone: string, propName: string): unknown {
    return phone ? value.filter((msg) => {
      return msg ? msg.to_number ? msg.to_number.indexOf(phone) !== -1 : value : value;
    }) : value;
  }

}
