<div class="navbar">
    <div class="row" style="margin: 0px; width: 100%;">
        <div class="col-md-6" style="display: flex; flex-wrap: nowrap;">
            <div>
                <a style="color: #fff;display: flex;height: 4rem;padding: 5px;"><img
                        src="../../../assets/images/logo.png"></a>
            </div>
        </div>
        <div class="col-md-6" style="display: flex; align-items: center; justify-content: flex-end;">
            <div class="navbar-item">
                <a (click)="openUsers()">Users</a>
            </div>
            <!-- <div class="navbar-item">
                <a (click)="openDevices()" [ngStyle]="{color : selected == 'dev' ? '#5edeff' : '#fff'}">Devices</a>
            </div> -->
            <div class="navbar-item">
                <a (click)="logout()">Logout</a>
            </div>
        </div>
    </div>
</div>
<div style="margin-top: 5rem;">
    <router-outlet></router-outlet>
</div>