<div *ngIf="!isLoad" class="container" style="margin-top: 8rem;">
    <div class="msg-box">

        <input class="form-control" [(ngModel)]="phone" type="tel" placeholder="Mobile number">
        <div class="col-md-2" style="display: flex;align-items: center;">
            <input [(ngModel)]="whatsapp" [checked]="whatsapp" type="checkbox" style="margin-right: 1rem;">
            <label style="margin: 0px;">WhatsApp</label>
        </div>
        <div class="col-md-2" style="display: flex;align-items: center;">
            <input [(ngModel)]="sms" [checked]="sms" type="checkbox" style="margin-right: 1rem;">
            <label style="margin: 0px;">SMS</label>
        </div>

        <button (click)="addBlacklistUser()" class="btn btn-dark"
            style="border-radius: 20px; margin-left: 1rem;padding: 0.375rem 1.75rem;">Add</button>
    </div>
    <!-- <div class="add-form">
        <button (click)="view('normal')" class="btn btn-light">End Users</button>
        <button (click)="view('special')" class="btn btn-light">Dealers</button>
        <button (click)="view('all')" class="btn btn-light">All</button>
    </div> -->
    <table class="table table-hover" id="dtBasicExample">
        <thead>
            <tr>
                <th>No</th>
                <th>User Mobile Number</th>
                <th>WhatsApp</th>
                <th>SMS</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody *ngFor="let user of blacklist; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td>{{user.phone}}</td>
                <td>
                    <label class="switch1">
                        <input type="checkbox" [checked]="user.whatsapp" (change)="blockWhatsApp($event, user, i)">
                        <span class="slider1 round1"></span>
                    </label>
                </td>
                <td>
                    <label class="switch2">
                        <input type="checkbox" [checked]="user.sms" (change)="blockSms($event, user, i)">
                        <span class="slider2 round2"></span>
                    </label>
                </td>
                <td>
                    <a class="btn btn-outline-light" (click)="deleteBlacklistUser(user, i)" style="padding: 2px;"><img
                            style="height: 1.5rem;" src="../../../assets/icons/delete.png"></a>
                    <!-- <a class="btn btn-outline-success" style="padding: 2px;"><img style="height: 1.5rem;"
                            src='../../../assets/icons/edit.png'></a> -->
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="isLoad" class="loader row">
    <div class="text-center">
        <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>