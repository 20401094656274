import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-driver-message',
  templateUrl: './driver-message.component.html',
  styleUrls: ['./driver-message.component.scss']
})
export class DriverMessageComponent implements OnInit {


  @Input() user;

  msg1 = '';
  msg2 = '';
  msg3 = '';

  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    // console.log(this.user);
    this.msg1 = this.user.driver_message_msg1 ? this.user.driver_message_msg1 : '';
    this.msg2 = this.user.driver_message_msg2 ? this.user.driver_message_msg2 : '';
    this.msg3 = this.user.driver_message_msg3 ? this.user.driver_message_msg3 : '';
  }

  saveValue() {
    this.modal.close({
      msg1: this.msg1,
      msg2: this.msg2,
      msg3: this.msg3
    })
  }

}
