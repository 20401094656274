import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService } from './../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  public loginForm: FormGroup;
  isLoading: boolean;
  passwordErr: boolean;
  errMsg = '';
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private AS: AdminService
  ) { }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (currentUser) {
      this.router.navigateByUrl('/bot/messages');
    }
    this.loginForm = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  submit() {
    this.isLoading = true;
    this.errMsg = '';
    let obj = {
      user_name: this.loginForm.value.user_name,
      password: this.loginForm.value.password
    }
    this.AS.signin(obj).subscribe((cred: any) => {
      localStorage.setItem('SMSID', cred.token);
      this.AS.getCurrentUserFromBack().subscribe(user => {
        this.isLoading = false;
        this.router.navigateByUrl('/bot/messages');
      }, err => {
        this.isLoading = false;
        this.errMsg = err.error.message;
      })
    }, err => {
      this.isLoading = false;
      this.errMsg = err.error.message;
      if (this.errMsg) {
        this.passwordErr = true
      }

    })
  }
}
