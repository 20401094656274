<div class="modal-header" style="background-color: #009688;">
    <h4 class="modal-title" id="modal-title" style="color: #fff;">Driver Messages</h4>
    <button type="button" style="color: #fff; opacity: 1;" class="close" aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding: 3rem 5rem;max-height: 70vh;overflow: auto;">
    <!-- <div class="col-md-12">
        <p style="color: gray;">How many seconds after the driver has arrived, would you like the customer not to be
            able to cancel their booking
            via WhatsApp and be asked to call instead?</p>
    </div> -->
    <div class="col-md-12" style="margin-bottom: 1rem;">
        <label style="font-weight: 500; color: gray;">Single booking message:</label>
        <textarea class="form-control" rows="6" [(ngModel)]="msg1" type="text" style="font-size: 0.8rem;"></textarea>
    </div>
    <div class="col-md-12" style="margin-bottom: 1rem;">
        <label style="font-weight: 500; color: gray;">Multiple bookings message:</label>
        <textarea class="form-control" rows="6" [(ngModel)]="msg2" type="text" style="font-size: 0.8rem;"></textarea>
    </div>
    <div class="col-md-12" style="margin-bottom: 1rem;">
        <label style="font-weight: 500; color: gray;">Confirmation of message sent to driver:</label>
        <textarea class="form-control" rows="6" [(ngModel)]="msg3" type="text" style="font-size: 0.8rem;"></textarea>
    </div>
</div>
<div class="modal-footer" style="justify-content: center">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('true')">
        Cancel
    </button>
    <button type="button" class="btn btn-outline-success" (click)="saveValue()">Save</button>
</div>
<!-- 
                let sendMsg = 'You do not have any active booking.\n\nType *HOME* to make a booking.';
        let sendMsg = 'Are you sure you want cancel this booking.\n\n1. Yes\n2. No';
    let sendMsg = 'Thank you for your response.\n\nType *HOME* to make a booking.';
            let sendMsg = 'Your selected booking has been cancelled.\n\nType *HOME* to make a booking.'; -->