import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BotService, AdminService } from './../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-type-settings',
  templateUrl: './vehicle-type-settings.component.html',
  styleUrls: ['./vehicle-type-settings.component.scss']
})
export class VehicleTypeSettingsComponent implements OnInit {

  name1 = '';
  type1 = '';

  name2 = '';
  type2 = '';

  name3 = '';
  type3 = '';

  botAdmin: any;
  vehicleButtons = [];
  constructor(
    private AS: AdminService,
    private router: Router,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.AS.getBotAdmin(currentUser._id).subscribe(res => {
      this.botAdmin = res;
      if (res.vehicle.length) {
        this.vehicleButtons = res.vehicle;
        this.name1 = res.vehicle[0].name;
        this.type1 = res.vehicle[0].type;
        this.name2 = res.vehicle[1].name;
        this.type2 = res.vehicle[1].type;
        this.name3 = res.vehicle[2].name;
        this.type3 = res.vehicle[2].type;
      }
    })
  }

  saveSettings() {
    this.vehicleButtons = [];
    if (this.name1 && this.type1) { this.vehicleButtons.push({ name: this.name1, type: this.type1.replace(' ', '') }) }
    if (this.name2 && this.type2) { this.vehicleButtons.push({ name: this.name2, type: this.type2.replace(' ', '') }) }
    if (this.name3 && this.type3) { this.vehicleButtons.push({ name: this.name3, type: this.type3.replace(' ', '') }) }
    let obj = {
      _id: this.botAdmin._id,
      vehicle: this.vehicleButtons
    }
    this.AS.updateBotAdmin(obj).subscribe(res => {
      this.modal.close()
    })

  }

}
