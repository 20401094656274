import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {


  selected = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('SMSADM'));
    if (!currentUser) {
      this.router.navigateByUrl('/sessions/admin/signin');
    }
    this.getCurrentUrl();
  }

  private getCurrentUrl() {
    const firstChild = this.activatedRoute.snapshot.firstChild
    if (firstChild != null) {
      if (firstChild['_routerState'].url == '/admin/users') {
        this.selected = 'user'
      } else if (firstChild['_routerState'].url == '/admin/devices') {
        this.selected = 'dev'
      }
    } else {
      this.selected = 'user'
      this.router.navigateByUrl('/admin/users')
    }
  }

  moveTo(link) {
    this.selected = link
    if (link == 'user') {
      this.router.navigateByUrl('/admin/users')
    } else if (link == 'dev') {
      this.router.navigateByUrl('/admin/devices')
    }
    //  else if (link == 'comp') {
    //       this.router.navigateByUrl('/admin/company')
    //     } else if (link == 'blk') {
    //       this.router.navigateByUrl('/admin/blocks')
    //     } else if (link == 'hsp') {
    //       this.router.navigateByUrl('/admin/habit-stick-program')
    //     }
  }

  openUsers() {
    this.selected = 'user'
    this.router.navigateByUrl('/admin/users')
  }

  openDevices() {
    this.selected = 'dev'
    this.router.navigateByUrl('/admin/devices')
  }

  logout() {
    localStorage.removeItem('SMSADM');
    this.router.navigateByUrl('/sessions/admin/signin');
  }

}
