import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-missedcall-settings',
  templateUrl: './missedcall-settings.component.html',
  styleUrls: ['./missedcall-settings.component.scss']
})
export class MissedcallSettingsComponent implements OnInit {

  @Input() user;

  missedcall_url = '';
  missedcall_phone = '';
  constructor(
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.missedcall_url = this.user.missedcall_url;
    this.missedcall_phone = this.user.missedcall_phone;
  }

  saveValue() {
    this.modal.close({ missedcall_url: this.missedcall_url, missedcall_phone: this.missedcall_phone })
  }

}
