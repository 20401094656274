<div class="sidebar">
    <!-- <div class="add-form">
        <input [(ngModel)]="msg_name" placeholder="Enter Message Name" type="text">
        <button [disabled]="!msg_name" (click)="addMessage()" class="btn btn-light">Save</button>
    </div> -->
    <!-- <div>
        <div class="msg-box">
            <div>
                <label style="margin: 0px;">ID : </label>
                <input [disabled]="!edit_id" [(ngModel)]="phone_id">
            </div>
            <button *ngIf="!edit_id" (click)="editId()" class="btn">Edit</button>
            <button *ngIf="edit_id" (click)="saveId()" class="btn">Save</button>
        </div>
    </div> -->
    <div>
        <div class="msg-box" style="background-color: #009688;color: #fff;">
            <a (click)="openBotCustomValues()" style="width: 100%; font-weight: bold;">Bot Setting</a>
            <!-- <a (click)="deleteMessage(i)"><img style="height: 2.2rem;"
                    src="../../../assets/icons/delete.png"></a> -->
        </div>
    </div>
    <div>
        <div class="msg-box" style="background-color: #009688;color: #fff;">
            <a (click)="openVehicleTypeSettings()" style="width: 100%; font-weight: bold;">Vehicle Buttons Setting</a>
            <!-- <a (click)="deleteMessage(i)"><img style="height: 2.2rem;"
                    src="../../../assets/icons/delete.png"></a> -->
        </div>
    </div>
    <div *ngFor="let msg of messageList; let i = index;">
        <div class="msg-box">
            <a (click)="openMessage(i)" style="width: 100%; font-weight: bold;">{{msg.msg_name}}</a>
            <!-- <a (click)="deleteMessage(i)"><img style="height: 2.2rem;"
                    src="../../../assets/icons/delete.png"></a> -->
        </div>
    </div>
</div>
<div class="main">
    <h5>Message name :</h5>
    <input [disabled]="isEdit" class="msg-name" [(ngModel)]="msgName" type="text">
    <h5>Message body :</h5>
    <textarea [disabled]="isEdit" rows="12" [(ngModel)]="normal_msg" placeholder="Message"
        class="form-control"></textarea>
    <!-- <div *ngIf="isEdit && currIndex == 0" style="margin-bottom: 2rem;">
        <label style="font-weight: bold; color: #07bfa5;">Keywords :</label>
        <div class="force-to-the-bottom">
            <tag-input disable=true maxItems='5' [(ngModel)]="keywordList">
            </tag-input>
        </div>
    </div> -->
    <!-- <div *ngIf="!isEdit && currIndex == 0" style="margin-bottom: 2rem;">
        <label style="font-weight: bold; color: #07bfa5;">Keywords :</label>
        <div class="force-to-the-bottom">
            <tag-input maxItems='5' [(ngModel)]="keywordList">
            </tag-input>
        </div>
    </div> -->
    <div style="text-align: center;">
        <button *ngIf="isEdit" (click)="editMessage()" class="btn btn-dark">Edit</button>
        <button *ngIf="!isEdit" (click)="setMessage()" class="btn btn-dark">Save</button>
    </div>
</div>

<div *ngIf="isLoad" class="loader row">
    <div class="text-center">
        <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>