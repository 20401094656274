import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from "./../../services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-bot-custom-values',
  templateUrl: './bot-custom-values.component.html',
  styleUrls: ['./bot-custom-values.component.scss']
})
export class BotCustomValuesComponent implements OnInit {

  zipcodeList = [];
  queryList = [];
  codeIndex = 0;
  queryIndex = 0;
  adminDetails: any;
  siteId = 0;
  userQuery = [];
  botQuery = "";
  outcode = "";
  sector = "";
  unit = "";
  additionalPrice = 0;
  pickupRadius = "";
  pickupLatitude = "";
  pickupLongitude = "";
  destinationLatitude = "";
  destinationLongitude = "";
  destinationRadius = "";
  isAirport = false;
  isEditCode = false;
  isEditQuery = false;
  isLoad = true;

  constructor(
    private BS: BotService,
    private AS: AdminService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.AS.getUser(currentUser._id).subscribe(user => {
      this.AS.getBotAdmin(currentUser._id).subscribe(admin => {
        this.adminDetails = admin;
        // console.log(admin);

        this.zipcodeList = this.adminDetails.zip_codes;
        this.queryList = this.adminDetails.query;
        this.pickupLatitude = admin.pickup_point_latitude;
        this.pickupLongitude = admin.pickup_point_longitude;
        this.pickupRadius = admin.pickup_radius;
        this.destinationLatitude = admin.destination_point_latitude;
        this.destinationLongitude = admin.destination_point_longitude;
        this.destinationRadius = admin.destination_radius;
        if (admin.additional_price) { this.additionalPrice = admin.additional_price }
        if (admin.site_id) { this.siteId = admin.site_id }
        this.isLoad = false;
      });
    });
  }

  selectCode(index) {
    this.isEditCode = true;
    this.outcode = this.zipcodeList[index].outcode;
    this.sector = this.zipcodeList[index].sector;
    this.unit = this.zipcodeList[index].unit;
    this.codeIndex = index;
  }

  updateZipcode() {
    let obj = {
      outcode: this.outcode,
      sector: this.sector,
      unit: this.unit,
    };
    this.zipcodeList[this.codeIndex].outcode = obj.outcode;
    this.zipcodeList[this.codeIndex].sector = obj.sector;
    this.zipcodeList[this.codeIndex].unit = obj.unit;
    let editObj = {
      _id: this.adminDetails._id,
      zip_codes: this.zipcodeList,
    };
    this.AS.updateBotAdmin(editObj).subscribe((res) => {
      this.isEditCode = false;
      this.outcode = "";
      this.sector = "";
      this.unit = "";
    });
  }

  addZipcode() {
    let obj = {
      outcode: this.outcode,
      sector: this.sector,
      unit: this.unit,
    };
    this.zipcodeList.push(obj);
    let editObj = {
      _id: this.adminDetails._id,
      zip_codes: this.zipcodeList,
    };
    this.AS.updateBotAdmin(editObj).subscribe((res) => {
      this.outcode = "";
      this.sector = "";
      this.unit = "";
    });
  }

  deleteCode(index) {
    this.zipcodeList.splice(index, 1);
    let editObj = {
      _id: this.adminDetails._id,
      zip_codes: this.zipcodeList,
    };
    this.AS.updateBotAdmin(editObj).subscribe((res) => {
      this.outcode = "";
      this.sector = "";
      this.unit = "";
    });
  }

  selectAirport(index) {
    this.isEditQuery = true;
    this.userQuery = this.queryList[index].user_query;
    this.botQuery = this.queryList[index].bot_query;
    this.queryIndex = index;
  }

  updateAirport() {
    let obj = {
      user_query: this.userQuery.map(uq => uq.value.toLowerCase()),
      bot_query: this.botQuery.toLowerCase(),
    };
    this.queryList[this.queryIndex] = obj.user_query;
    this.queryList[this.queryIndex] = obj.bot_query;
    let editObj = {
      _id: this.adminDetails._id,
      query: this.queryList,
    };
    this.AS.updateBotAdmin(editObj).subscribe((res) => {
      this.isEditQuery = false;
      this.userQuery = [];
      this.botQuery = "";
    });
  }

  addAirport() {
    let obj = {
      user_query: this.userQuery.map(uq => uq.value.toLowerCase()),
      bot_query: this.botQuery.toLowerCase(),
      isAirport: this.isAirport
    };

    this.queryList.push(obj);
    let editObj = {
      _id: this.adminDetails._id,
      query: this.queryList,
    };
    this.AS.updateBotAdmin(editObj).subscribe((res) => {
      this.isEditQuery = false;
      this.userQuery = [];
      this.botQuery = "";
      this.isAirport = false;
    });
  }

  deleteAirport(index) {
    this.queryList.splice(index, 1);
    let editObj = {
      _id: this.adminDetails._id,
      query: this.queryList,
    };
    this.AS.updateBotAdmin(editObj).subscribe((res) => {
      this.isEditQuery = false;
      this.userQuery = [];
      this.botQuery = "";
    });
  }

  updateCenterPoint() {
    this.isLoad = true;
    let obj = {
      _id: this.adminDetails._id,
      pickup_point_latitude: this.pickupLatitude,
      pickup_point_longitude: this.pickupLongitude,
      pickup_radius: this.pickupRadius,
      destination_point_latitude: this.destinationLatitude,
      destination_point_longitude: this.destinationLongitude,
      destination_radius: this.destinationRadius
    }

    // console.log(obj);

    this.AS.updateBotAdmin(obj).subscribe(res => {
      this.isLoad = false;
      // console.log(res);
    })

  }

  updateAdditionalPrice() {
    this.isLoad = true;
    let obj = {
      _id: this.adminDetails._id,
      additional_price: this.additionalPrice
    }

    // console.log(obj);

    this.AS.updateBotAdmin(obj).subscribe(res => {
      this.isLoad = false;
      // console.log(res);
    })
  }

  updateSiteID() {
    this.isLoad = true;
    let obj = {
      _id: this.adminDetails._id,
      site_id: this.siteId
    }

    // console.log(obj);

    this.AS.updateBotAdmin(obj).subscribe(res => {
      this.isLoad = false;
      // console.log(res);
    })
  }

}
