import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from './../../services';
import { Router } from '@angular/router';
import { VehicleTypeSettingsComponent } from '../vehicle-type-settings/vehicle-type-settings.component'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bot-messages',
  templateUrl: './bot-messages.component.html',
  styleUrls: ['./bot-messages.component.scss']
})
export class BotMessagesComponent implements OnInit {

  private serviceModal: NgbModalRef;

  adminDetails: any;
  edit_id = false;
  phone_id: string;
  limitMsg: boolean;
  k_word: boolean = false;
  isEdit: boolean = true;
  isLoad: boolean = true;
  normal_msg = '';
  msg_name = '';
  keywordList = [];
  msgName = '';
  keyword = [];
  messageList = [];
  currMessage: any;
  currIndex: number = 0;

  constructor(
    private BS: BotService,
    private AS: AdminService,
    private _serviceModal: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.AS.getUser(currentUser._id).subscribe(admin => {
      this.adminDetails = admin;
      if (!this.adminDetails.wtsp_bot) {
        this.router.navigateByUrl('/sessions/signin');
      }
      this.BS.getBotMessages(currentUser._id).subscribe(result => {

        let res: any = this.messageList = result.sort((a, b) => { return a.msg_index - b.msg_index }) //.filter(f => f.msg_index == 2)
        res = this.moveMessage(res, 3, 6)
        res = this.moveMessage(res, 15, 4)
        res = this.moveMessage(res, 20, 5)
        res = this.moveMessage(res, 21, 6)
        res = this.moveMessage(res, 22, 7)
        res = this.moveMessage(res, 23, 8)
        res = this.moveMessage(res, 24, 13)
        res = this.moveMessage(res, 25, 14)
        res = this.moveMessage(res, 26, 15)
        res = this.moveMessage(res, 27, 16)
        res = this.moveMessage(res, 34, 23)
        res = this.moveMessage(res, 35, 24)
        res = this.moveMessage(res, 36, 25)
        res = this.moveMessage(res, 32, 1)
        res = this.moveMessage(res, 27, 2)
        this.messageList = res;

        if (this.messageList.length > 0) {
          this.currMessage = this.messageList[0];
          this.msgName = this.currMessage.msg_name;
          this.normal_msg = this.messageList[0].msg_body;
          if (this.normal_msg == '') { this.isEdit = false }
          this.isLoad = false;
        }
      })
    })
  }

  private moveMessage(arr: any, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  openBotCustomValues() {
    this.router.navigateByUrl('/bot/custom-values')
  }

  openVehicleTypeSettings() {
    this.serviceModal = this._serviceModal.open(VehicleTypeSettingsComponent, { size: 'lg' });
    this.serviceModal.result.then((result) => {
      // this.isLoad = true;

      // let obj = {
      //   _id: this.adminDetails._id,
      //   booking_confirmation_time: result.time,
      //   booking_confirmation_phone: result.phone
      // }

      // this.AS.updateUser(obj).subscribe(res => {
      //   this.isLoad = false;
      //   this.ngOnInit();
      // })
    }, (reason) => {

    })
  }

  editId() {
    this.edit_id = true;
  }

  saveId() {
    let obj = {
      _id: this.adminDetails._id,
      phone_id: this.phone_id
    }
    this.AS.updateAdmin(obj).subscribe(res => {
      this.edit_id = false;
    })
  }

  textChange() {
    this.isEdit = true;
  }

  setMessage() {
    // let keywords = [];
    // this.keywordList.map(a => {
    //   let kObj = {
    //     value: a.value
    //   }
    //   keywords.push(kObj)
    // });
    let obj = {
      _id: this.currMessage._id,
      msg_name: this.msgName,
      msg_body: this.normal_msg,
      // keywords: keywords
    }

    this.BS.updateMessage(obj).subscribe(result => {
      this.messageList[this.currIndex] = result
      this.isEdit = true;
      // this.keywordList = [];
      // this.messageList[this.currIndex].keywords.map(word => {
      //   let obj = {
      //     display: word.value,
      //     value: word.value
      //   }
      //   this.keywordList.push(obj);
      // })
    })
  }

  openMessage(index) {
    // this.k_word = true;
    // this.limitMsg = false;
    // if (index == 0 || index == 1) { this.k_word = false; }
    // if (index == 2) { this.k_word = false; this.limitMsg = true }
    this.isEdit = true;
    this.currMessage = this.messageList[index];
    this.msgName = this.currMessage.msg_name;
    this.currIndex = index;
    // this.keywordList = []
    this.normal_msg = this.currMessage.msg_body;
    // if (this.normal_msg == '') { this.isEdit = false }

    // this.currMessage.keywords.map(word => {
    //   let obj = {
    //     display: word.value,
    //     value: word.value
    //   }
    //   this.keywordList.push(obj);
    // })
  }

  // deleteMessage(index) {
  //   this.BS.deleteMessage(this.messageList[index]._id).subscribe(result => {
  //     this.messageList.splice(index, 1);
  //     this.openMessage(0)
  //   })
  // }

  editMessage() {
    this.isEdit = false;
  }

  // addMessage() {
  //   let obj = {
  //     msg_name: this.msg_name,
  //     msg_body: '',
  //     msg_no: 0
  //   }
  //   this.BS.setMessage(obj).subscribe(result => {
  //     this.messageList.push(result);
  //     this.msg_name = '';
  //   })
  // }

  // onDefaultMsg(e, msg, i) {
  //   if (e.target.checked) {
  //     let obj = {
  //       _id: msg._id,
  //       isSpecial: true,
  //     }

  //     this.BS.updateMessage(obj).subscribe(result => {
  //       this.messageList[i] = result
  //     })
  //   }
  //   else if (!e.target.checked) {
  //     let obj = {
  //       _id: msg._id,
  //       isSpecial: false,
  //     }

  //     this.BS.updateMessage(obj).subscribe(result => {
  //       this.messageList[i] = result
  //     })
  //   }
  // }

}
