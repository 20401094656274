import { BotService } from './bot/bot.service';
import { AdminService } from './admin/admin.service';

export const Services: any[] = [
    BotService,
    AdminService
];

export {
    BotService,
    AdminService
}