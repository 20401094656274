import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminService } from './../../services';

@Component({
  selector: 'app-message-settings',
  templateUrl: './message-settings.component.html',
  styleUrls: ['./message-settings.component.scss']
})
export class MessageSettingsComponent implements OnInit {

  @Input() user;
  isLoad = true;

  constructor(
    private AS: AdminService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.isLoad = false;
  }

  changeSettings(e, type) {
    this.isLoad = true;
    let obj = {
      _id: this.user._id
    }
    obj[type] = e.target.checked

    this.AS.updateUser(obj).subscribe(res => {
      this.isLoad = false;
    })
  }

}
