<div class="container main">
    <div class="formargin">

    </div>
    <div class="firstbody">
        <div class="row margin-0">
            <!-- <div class="col-md-12 p-0"> -->
            <div class="col-md-6 forheading">
                <h1>Create Broadcast</h1>
            </div>
            <div class="col-md-6 forButton">
                <a (click)="setBroadcast()">Send</a>
            </div>
            <!-- </div> -->
            <div class="col-md-6 for_input">
                <label for="">Name:</label>
                <input type="text" [(ngModel)]="name">
            </div>
            <div class="col-md-6 for_input" (mouseenter)="showText()" (mouseleave)="hideText()">
                <label for="">Delay Time (seconds):</label>
                <input type="number" [(ngModel)]="delay">
                <span class="tooltiptext" *ngIf="showHoverText">Message sending delay between each
                    contact.</span>
            </div>
            <div class="col-md-6 for_input">
                <label for="">Update Excel Sheet:</label>
                <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls, .csv">
            </div>
            <div class="col-md-6 for_input">
                <label for="">Contacts column:</label>
                <!-- <input type="number" [(ngModel)]="column"> -->
                <select [(ngModel)]="column">
                    <option *ngFor="let col of excelHeaders;let i = index;" [value]="i+1">{{col}}</option>
                </select>
            </div>
            <div class="col-md-12 fortext">
                <label for="">Message</label>
                <textarea name="" id="" [(ngModel)]="messageBody"></textarea>
            </div>
        </div>

        <!-- <div class="forright">
            <div class="forButton">
                <a href="">Send</a>
            </div>
        </div> -->

    </div>
    <div class="secondBody">
        <div class="forheading">
            <h1>
                Contact list
            </h1>
        </div>
        <div class="forTable table">
            <table>
                <thead>
                    <tr>
                        <th *ngFor="let header of excelHeaders">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of excelData">
                        <td *ngFor="let cell of row">{{ cell }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div *ngIf="isLoad" class="chip">Broadcast initiated.</div>