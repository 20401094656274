<div class="auth-layout-wrap">
    <div class="bg-layer"></div>
    <div class="auth-content">
        <div class="col-md-10" style="margin: auto;margin-bottom: 5rem;">
            <div class="text-center mb-4" style="height: 100px;">
                <img style="width: 100%;" src="./assets/images/logo.png" alt="">
            </div>
        </div>
        <div class="col-md-8" style="margin: auto;">
            <div class="row">
                <div class="col-md-12">
                    <div class="p-4">
                        <h6 *ngIf="passwordErr"
                            style="text-align: center;color: #ff4747;font-size: 0.8rem;margin-bottom: 1rem;">Invalid
                            username or
                            password</h6>
                        <form *ngIf="loginForm" [formGroup]="loginForm" class="form-group">
                            <div class="form-group">
                                <input id="user_name" formControlName="user_name" class="form-control" type="text"
                                    placeholder="Username">
                            </div>
                            <div class="form-group">
                                <input id="password" formControlName="password" class="form-control" type="password"
                                    placeholder="Password">
                            </div>
                            <button (click)="submit()" [disabled]="!loginForm.valid" class="btn btn-primary btn-block"
                                style="opacity: 1;padding: 10px;border-radius: 10px;">Sign
                                In</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- </div> -->
</div>