<div class="container col-md-10" style="margin-top: 8rem;">
    <!-- <div class="add-form">
        <button (click)="view('normal')" class="btn btn-light">End Users</button>
        <button (click)="view('special')" class="btn btn-light">Dealers</button>
        <button (click)="view('all')" class="btn btn-light">All</button>
    </div> -->
    <table class="table table-hover" id="dtBasicExample">
        <thead>
            <tr>
                <th>No</th>
                <th>User Name</th>
                <th>User ID</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody *ngFor="let user of userList; let i = index">
            <tr>
                <td>{{i+1}}</td>
                <td>{{user.name}}</td>
                <td>{{user._id}}</td>
                <td>
                    <label class="switch">
                        <input type="checkbox" [checked]="user.active" (change)="userActivation($event, user, i)">
                        <span class="slider round"></span>
                    </label>
                </td>
                <td>
                    <a class="btn btn-outline-info" (click)="openDevices(user, i)"
                        style="font-size: 0.8rem;margin-right: 5px;">Devices</a>
                    <a class="btn btn-outline-success" (click)="showUserDetails(user, i)"
                        style="font-size: 0.8rem;">Details</a>
                    <!-- <a class="btn btn-outline-success" style="padding: 2px;"><img style="height: 1.5rem;"
                            src='../../../assets/icons/edit.png'></a> -->
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="isLoad" class="loader row">
    <div class="text-center">
        <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>