import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from './../../services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blacklist-user',
  templateUrl: './blacklist-user.component.html',
  styleUrls: ['./blacklist-user.component.scss']
})
export class BlacklistUserComponent implements OnInit {

  currentUser: any;
  blacklist = [];
  phone = '';
  isLoad = true;
  whatsapp = false;
  sms = false;

  constructor(
    private router: Router,
    private BS: BotService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!this.currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.BS.getBlacklistUser(this.currentUser._id).subscribe(list => {
      this.blacklist = list
      this.isLoad = false;
    })
  }

  addBlacklistUser() {
    this.isLoad = true;
    let obj = {
      user_id: this.currentUser._id,
      phone: this.phone,
      whatsapp: this.whatsapp,
      sms: this.sms
    }
    this.BS.setBlacklistUser(obj).subscribe(usr => {
      this.phone = '';
      this.blacklist.push(usr)
      this.isLoad = false;
      this.whatsapp = false;
      this.sms = false;
    }, err => {
      if (err.status == 400) {
        alert('Number already exist..!')
        this.isLoad = false;
        this.whatsapp = false;
        this.sms = false;
        this.phone = '';
      }
    })
  }

  blockWhatsApp(e, user, index) {
    this.isLoad = true;
    let obj = {
      _id: user._id,
      whatsapp: e.target.checked
    }

    this.BS.updateBlacklistUser(obj).subscribe(usr => {
      this.blacklist[index] = usr
      this.isLoad = false;
      this.whatsapp = false;
      this.sms = false;
    }, err => {
      this.phone = '';
      this.isLoad = false;
      this.whatsapp = false;
      this.sms = false;
    })
  }

  blockSms(e, user, index) {
    this.isLoad = true;
    let obj = {
      _id: user._id,
      sms: e.target.checked
    }

    this.BS.updateBlacklistUser(obj).subscribe(usr => {
      this.blacklist[index] = usr
      this.isLoad = false;
      this.whatsapp = false;
      this.sms = false;
    }, err => {
      this.phone = '';
      this.isLoad = false;
      this.whatsapp = false;
      this.sms = false;
    })
  }

  deleteBlacklistUser(user, index) {
    this.BS.deleteBlacklistUser(user._id).subscribe(usr => {
      this.blacklist.splice(index, 1)
    })
  }
}
