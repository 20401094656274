<div class="modal-header" style="background-color: #009688;">
    <h4 class="modal-title" id="modal-title" style="color: #fff;">Settings</h4>
    <button type="button" style="color: #fff; opacity: 1;" class="close" aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding: 2rem">
    <div class="msg-box">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Otp Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.otp_sms" (change)="changeSettings($event, 'otp_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Missed Call Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.missedcall_sms"
                    (change)="changeSettings($event, 'missedcall_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.booking_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Booking Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.booking_sms" (change)="changeSettings($event, 'booking_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.late_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Late Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.late_sms" (change)="changeSettings($event, 'late_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.track_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Track Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.track_sms" (change)="changeSettings($event, 'track_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.arrived_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Arrived Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.arrived_sms" (change)="changeSettings($event, 'arrived_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.paylink_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Paylink Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.paylink_sms" (change)="changeSettings($event, 'paylink_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.review_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Review Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.review_sms" (change)="changeSettings($event, 'review_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.dreview_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Driver review Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.dreview_sms" (change)="changeSettings($event, 'dreview_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.preAuth_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">PreAuth Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.preAuth_sms" (change)="changeSettings($event, 'preAuth_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.custom1_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Custom 1 Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.custom1_sms" (change)="changeSettings($event, 'custom1_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div class="msg-box" *ngIf="user.custom2_index >= 0">
        <div class="col-md-6" style="text-align: center;">
            <a style="width: 100%; font-weight: bold;">Custom 2 Sms</a>
        </div>
        <div class="col-md-6" style="text-align: center;">
            <label class="switch">
                <input type="checkbox" [checked]="user.custom2_sms" (change)="changeSettings($event, 'custom2_sms')">
                <span class="slider round"></span>
            </label>
        </div>
    </div>
    <div *ngIf="isLoad" class="loader row">
        <div class="text-center">
            <div class="loader1">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('true')">
        Close
    </button>
</div>