<div class="container" style="margin: auto;padding: 5rem 1rem;">
    <div class="row">
        <div class="col-md-6" style="margin-bottom:1rem;">
            <div class="postcode-box">
                <h3 style="text-align: center;text-decoration: underline;">Additional Price</h3>
                <div class="col-lg-10 code-field-area" style="margin: auto;padding: 1rem 0;">
                    <div class="col-md-12" style="display: flex;justify-content: center;">
                        <!-- <label>Radius</label> -->
                        <div class="coordinates-box">
                            <input [(ngModel)]="additionalPrice" class="coordinates-field" type="number">
                        </div>
                    </div>
                </div>
                <div style="text-align: center; padding: 5px 0px">
                    <button [disabled]="additionalPrice < 0 || additionalPrice == null"
                        (click)="updateAdditionalPrice()" class="btn btn-info" style="margin: 5px">
                        Update
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-6" style="margin-bottom:1rem;">
            <div class="postcode-box">
                <h3 style="text-align: center;text-decoration: underline;">Site ID</h3>
                <div class="col-lg-10 code-field-area" style="margin: auto;padding: 1rem 0;">
                    <div class="col-md-12" style="display: flex;justify-content: center;">
                        <!-- <label>Radius</label> -->
                        <div class="coordinates-box">
                            <input [(ngModel)]="siteId" class="coordinates-field" type="number">
                        </div>
                    </div>
                </div>
                <div style="text-align: center; padding: 5px 0px">
                    <button [disabled]="siteId <0 || additionalPrice == null" (click)="updateSiteID()"
                        class="btn btn-info" style="margin: 5px">
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="margin-bottom:1rem;">
            <div class="postcode-box">
                <h3 style="text-align: center;text-decoration: underline;">Radius And Center Point</h3>
                <div class="col-lg-10 code-field-area" style="margin: auto;padding: 1rem 0;">
                    <div class="col-md-6" style="display: flex;justify-content: left;">
                        <label class="col-md-5">Pickup Center Point:</label>
                        <div class="coordinates-box">
                            <input [(ngModel)]="pickupLatitude" class="coordinates-field" type="number"
                                placeholder="Lat">:
                            <input [(ngModel)]="pickupLongitude" class="coordinates-field" type="number"
                                placeholder="Long">
                        </div>
                    </div>
                    <div class="col-md-6" style="display: flex;justify-content: center;">
                        <label>Radius</label>
                        <div class="coordinates-box">
                            <input [(ngModel)]="pickupRadius" class="coordinates-field" type="number">
                        </div>
                    </div>
                </div>
                <div class="col-lg-10 code-field-area" style="margin: auto;padding: 1rem 0;">
                    <div class="col-md-6" style="display: flex;justify-content: left;">
                        <label class="col-md-5">Destination Center Point:</label>
                        <div class="coordinates-box">
                            <input [(ngModel)]="destinationLatitude" class="coordinates-field" type="number"
                                placeholder="Lat">:
                            <input [(ngModel)]="destinationLongitude" class="coordinates-field" type="number"
                                placeholder="Long">
                        </div>
                    </div>
                    <div class="col-md-6" style="display: flex;justify-content: center;">
                        <label>Radius</label>
                        <div class="coordinates-box">
                            <input [(ngModel)]="destinationRadius" class="coordinates-field" type="number">
                        </div>
                    </div>
                </div>
                <div style="text-align: center; padding: 5px 0px">
                    <button
                        [disabled]="!pickupRadius || !pickupLongitude || !pickupLatitude ||!destinationRadius || !destinationLongitude || !destinationLatitude"
                        (click)="updateCenterPoint()" class="btn btn-info" style="margin: 5px">
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="margin-bottom:1rem;">
            <div class="postcode-box">
                <h3 style="text-align: center;text-decoration: underline;">Post code list</h3>
                <div class="code-field-area">
                    <div class="code-field">
                        <label>Outcode:</label>
                        <input [(ngModel)]="outcode" type="text" />
                    </div>
                    <div class="code-field">
                        <label>Sector:</label>
                        <input [(ngModel)]="sector" type="text" />
                    </div>
                    <div class="code-field">
                        <label>Unit:</label>
                        <input [(ngModel)]="unit" type="text" />
                    </div>
                    <!-- <div class="code-field">
                        <button [disabled]="!isEditCode || !outcode" (click)="updateZipcode()" class="btn btn-info" style="margin: 5px;">Update</button>
                    </div>
                    <div class="code-field">
                        <button [disabled]="isEditCode || !outcode" (click)="addZipcode()" class="btn btn-success" style="margin: 5px;">Add</button>
                    </div> -->
                </div>
                <div style="text-align: center; padding: 5px 0px">
                    <button [disabled]="!isEditCode || !outcode" (click)="updateZipcode()" class="btn btn-info"
                        style="margin: 5px">
                        Update
                    </button>
                    <button [disabled]="isEditCode || !outcode" (click)="addZipcode()" class="btn btn-success"
                        style="margin: 5px">
                        Add
                    </button>
                </div>
                <div>
                    <div class="code-list">
                        <div style="display: flex; flex-wrap: wrap">
                            <div class="code-list-item" *ngFor="let code of zipcodeList; let i = index">
                                <span (click)="selectCode(i)">{{ code.outcode }}{{ code.sector }}{{ code.unit }}</span>
                                <a (click)="deleteCode(i)"><img style="height: 1.2rem; margin: 0px 5px"
                                        src="../../../assets/icons/remove.png" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="margin-bottom:1rem;">
            <div class="airport-box">
                <h3 style="text-align: center;text-decoration: underline;">Query list</h3>
                <div class="airport-field-area">
                    <div class="airport-field">
                        <label>User Query:</label>
                        <div class="force-to-the-bottom">
                            <tag-input maxItems='5' [(ngModel)]="userQuery">
                            </tag-input>
                        </div>
                    </div>
                    <div class="airport-field">
                        <label>Alternate:</label>
                        <input style="padding: 5px;" [(ngModel)]="botQuery" type="text" />
                    </div>
                    <div class="airport-field" style="display: flex; align-items: baseline;">
                        <input [(ngModel)]="isAirport" type="checkbox" />
                        <label style="margin-left: 10px;">Airport address</label>
                    </div>
                    <!-- <div class="code-field">
                        <button [disabled]="!isEditQuery || !outcode" (click)="updateZipcode()" class="btn btn-info" style="margin: 5px;">Update</button>
                    </div>
                    <div class="code-field">
                        <button [disabled]="isEditQuery || !outcode" (click)="addZipcode()" class="btn btn-success" style="margin: 5px;">Add</button>
                    </div> -->
                </div>
                <div style="text-align: center; padding: 5px 0px">
                    <!-- <button [disabled]="
              !isEditQuery || !userQuery.length || !botQuery
            " (click)="updateAirport()" class="btn btn-info" style="margin: 5px">
            Update
          </button> -->
                    <button [disabled]="
              isEditQuery || !userQuery.length || !botQuery
            " (click)="addAirport()" class="btn btn-success" style="margin: 5px">
                        Add
                    </button>
                </div>
                <div>
                    <div class="query-code-heading">
                        <div style="display: flex; flex-wrap: wrap">
                            <div class="qry-list-item">
                                <div class="col-md-3" style="text-align: center;font-weight: bold;">Bot Query</div>
                                <div class="col-md-8" style="text-align: center;font-weight: bold;">User Queries</div>
                            </div>
                        </div>
                    </div>
                    <div class="query-code-list">
                        <div style="display: flex; flex-wrap: wrap">
                            <div class="qry-list-item" *ngFor="let qry of queryList; let i = index">
                                <div class="col-md-3" style="border-right: 1px solid #c1c1c1;">{{qry.bot_query}}</div>
                                <div class="col-md-8"
                                    style="display: flex; justify-content: space-around; flex-wrap: wrap;">
                                    <div *ngFor="let uQry of qry.user_query">{{uQry}}</div>
                                </div>
                                <a style="display: flex;justify-content: center;align-items: center;"
                                    (click)="deleteAirport(i)"><img style="height: 1.2rem; margin: 0px 5px"
                                        src="../../../assets/icons/remove.png" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoad" class="loader row">
    <div class="text-center">
        <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>