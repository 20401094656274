import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from './../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component'

@Component({
  selector: 'app-mybroadcast',
  templateUrl: './mybroadcast.component.html',
  styleUrls: ['./mybroadcast.component.scss']
}

) export class MybroadcastComponent implements OnInit {

  private serviceModal: NgbModalRef;
  showHoverText = false;
  showContactHoverText = false;
  adminDetails: any;
  broadcastList = [];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _serviceModal: NgbModal,
    private AS: AdminService,
    private BS: BotService
  ) { }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.AS.getUser(currentUser._id).subscribe(admin => {
      this.adminDetails = admin;
      this.BS.getBroadcast(this.adminDetails._id).subscribe(res => {
        this.broadcastList = res;
        // console.log(res);

      })
    })
  }

  terminateBroadcast(index, broadcast) {
    this.serviceModal = this._serviceModal.open(ConfirmationModalComponent);
    this.serviceModal.componentInstance.msg = "Are you sure you want to terminate this broadcast?"
    this.serviceModal.result.then((result) => {
      this.BS.pauseBroadcast(broadcast._id).subscribe(res => {
        this.BS.getBroadcast(this.adminDetails._id).subscribe(res => {
          this.broadcastList = res;
        })
      })
    }, (reason) => {
      // console.log('cross');
    })
  }

  deleteBroadcast(index, broadcast) {
    this.serviceModal = this._serviceModal.open(ConfirmationModalComponent);
    this.serviceModal.componentInstance.msg = "Are you sure you want to delete this?"
    this.serviceModal.result.then((result) => {
      this.BS.deleteBroadcast(broadcast._id).subscribe(res => {
        this.broadcastList.splice(index, 1);
      })
    }, (reason) => {
      // console.log('cross');
    })
  }

  addBroadcast() {
    this.router.navigateByUrl('/bot/broadcast')
  }

  showText() {
    this.showHoverText = true;

  }

  hideText() {
    this.showHoverText = false;
  }

  hideTextOfContact() {
    this.showContactHoverText = false;
  }

  showTextOfContact() {
    this.showContactHoverText = true
  }

}