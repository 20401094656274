<div class="row" style="margin: 0px;">
    <div class="col-md-10" style="justify-content: center;font-size: 0.75rem;margin: auto;">
        <div class="main col-md-10" style="margin: 2rem auto;">
            <div *ngIf="currentUser" class="col-md-12" style="padding: 0px;">
                <div class="whatsapp-bot-btn-box">
                    <label class="whatsapp-bot-heading">WhatsApp Bot</label>
                    <label class="big-switch">
                        <input type="checkbox" [checked]="currentUser.wtsp_bot" (change)="botActivation($event)">
                        <span class="big-slider big-round"></span>
                    </label>
                </div>
            </div>
            <form *ngIf="userForm" [formGroup]="userForm" class="form-group" style="margin: 0px;">
                <div class="row">
                    <!-- <div class="col-md-12"> -->
                    <div class="form-group col-md-6">
                        <label for="name">Name</label>
                        <input id="name" formControlName="name" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="_id">User ID</label>
                        <input id="_id" formControlName="_id" class="form-control" type="text">
                    </div>

                    <div class="form-group col-md-6">
                        <label for="user_name">Username</label>
                        <input id="user_name" formControlName="user_name" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="password">Password</label>
                        <input id="password" formControlName="password" class="form-control" type="text">
                    </div>

                    <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-12">
                        <label for="user_name">Driver Api Url</label>
                        <input id="user_name" formControlName="icabbi_gateway" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-12">
                        <label for="password">Driver Api Key's (app_key:secret_key)</label>
                        <input id="password" formControlName="token" class="form-control" type="text">
                    </div>

                    <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-6">
                        <label for="getway">SMS Gateway</label>
                        <input id="getway" formControlName="getway" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="server_password">Server Password</label>
                        <input id="server_password" formControlName="server_password" class="form-control" type="text">
                    </div>

                    <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-6">
                        <label for="app_api">SMS App api</label>
                        <input id="app_api" formControlName="app_api" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="app_key">Api key</label>
                        <input id="app_key" formControlName="app_key" class="form-control" type="text">
                    </div>

                    <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-6">
                        <label for="faretext_getway">SM Gateway</label>
                        <input id="faretext_getway" formControlName="faretext_getway" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="faretext_server_password">SM Server Password</label>
                        <input id="faretext_server_password" formControlName="faretext_server_password"
                            class="form-control" type="text">
                    </div>

                    <div class="form-group col-md-6">
                        <label for="faretext_uid">User ID</label>
                        <input id="faretext_uid" formControlName="faretext_uid" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="faretext_sid">Sender ID</label>
                        <input id="faretext_sid" formControlName="faretext_sid" class="form-control" type="text">
                    </div>

                    <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-12">
                        <label for="sms_ejointech_url">EjoinTech Gateway</label>
                        <input id="sms_ejointech_url" formControlName="sms_ejointech_url" class="form-control"
                            type="text">
                    </div>
                    <div class="form-group col-md-12">
                        <label for="sms_ejointech_token">EjoinTech Token</label>
                        <input id="sms_ejointech_token" formControlName="sms_ejointech_token" class="form-control"
                            type="text">
                    </div>

                    <!-- <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-6">
                        <label for="mobitext_gateway">Mobitext Gateway</label>
                        <input id="mobitext_gateway" formControlName="mobitext_gateway" class="form-control"
                            type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="mobitext_token">Mobitext Token</label>
                        <input id="mobitext_token" formControlName="mobitext_token" class="form-control" type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="mobitext_username">Mobitext Username</label>
                        <input id="mobitext_username" formControlName="mobitext_username" class="form-control"
                            type="text">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="mobitext_password">Mobitext Password</label>
                        <input id="mobitext_password" formControlName="mobitext_password" class="form-control"
                            type="text">
                    </div> -->

                    <div class="col-md-12 separator"></div>

                    <div class="form-group col-md-6">
                        <div style="display: flex;">
                            <label style="margin-right: 5px;">Otp Message</label>
                            <label class="switch">
                                <input type="checkbox" formControlName="otp_msg">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <select formControlName="sms_api" class="form-control">
                            <option [value]="1">SMS Gateway</option>
                            <option [value]="2">Faretext Gateway</option>
                            <option [value]="3">SMS App</option>
                            <option [value]="4">SM SMS</option>
                            <option [value]="5">Mobitext</option>
                            <!-- <option [value]="6">Voodoo</option> -->
                            <option [value]="7">EjoinTech</option>
                        </select>
                        <!-- <div class="row">
                                <div class="col-md-6 radio-field">
                                    <input type="radio" formControlName="sms_api" name="sms_api" [value]="1">
                                    <label>SMS Gateway</label>
                                </div>
                                <div class="col-md-6 radio-field">
                                    <input type="radio" formControlName="sms_api" name="sms_api" [value]="2">
                                    <label>Faretext Gateway</label>
                                </div>
                                <div class="col-md-6 radio-field">
                                    <input type="radio" formControlName="sms_api" name="sms_api" [value]="3">
                                    <label>SMS App</label>
                                </div>
                                <div class="col-md-6 radio-field">
                                    <input type="radio" formControlName="sms_api" name="sms_api" [value]="4">
                                    <label>SM SMS</label>
                                </div>
                                <div class="col-md-6 radio-field">
                                    <input type="radio" formControlName="sms_api" name="sms_api" [value]="5">
                                    <label>Mobitext</label>
                                </div>
                                <div class="col-md-6 radio-field">
                                    <input type="radio" formControlName="sms_api" name="sms_api" [value]="6">
                                    <label>Voodoo</label>
                                </div>
                            </div> -->
                    </div>
                    <!-- </div>
                    <div class="col-md-6"> -->
                    <div class="form-group col-md-6">
                        <div style="display: flex;">
                            <label for="rid" style="margin-right: 5px;">Message Response</label>
                            <label class="switch">
                                <input type="checkbox" formControlName="msg_response">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <input id="rid" formControlName="response_api" class="form-control" placeholder="Response API"
                            type="text">
                    </div>
                    <!-- </div> -->
                </div>
                <div class="row" style="margin-top: 1rem;justify-content: flex-end;padding: 0 15px;">
                    <button *ngIf="isEdit" (click)="saveUserDetails()" [disabled]="!userForm.valid"
                        class="btn btn-primary" style="float: right;">Save</button>
                    <button *ngIf="!isEdit" (click)="editUserDetails()" class="btn btn-success"
                        style="float: right;">Edit</button>
                </div>
            </form>
        </div>
        <div class="main col-md-10" style="margin: 2rem auto;">
            <div class="row">
                <div class="col-md-6">
                    <label>Device Name:</label>
                    <input class="form-control" [(ngModel)]="device_name" type="text">
                </div>
                <div class="col-md-6">
                    <label>Message limit:</label>
                    <input class="form-control" [(ngModel)]="limit" type="number">
                </div>
            </div>
            <div class="row" style="margin-top: 1rem;">
                <div class="col-md-6">
                    <label>Device ID:</label>
                    <input class="form-control" [(ngModel)]="device_id" type="text">
                </div>
                <div class="col-md-6" style="display: flex;flex-wrap: nowrap; padding: 0;">
                    <div class="col-md-6">
                        <label>Delay time:(min)</label>
                        <input class="form-control" [(ngModel)]="delayTime" type="number">
                    </div>
                    <div class="col-md-6">
                        <label>Delay time:(sec)</label>
                        <input class="form-control" [(ngModel)]="afterDelayTime" type="number">
                    </div>
                </div>
            </div>
            <div style="margin-top: 1rem;">
                <label>Token:</label>
                <input class="form-control" [(ngModel)]="token" type="text">
            </div>
            <div class="row" style="margin-top: 1rem;">
                <!-- <div class="col-md-4 radio-field">
                    <input type="radio" [(ngModel)]="apiPlatform" id="was" value="wassenger">
                    <label for="was">Wassenger</label>
                </div> -->
                <div class="col-md-4 radio-field">
                    <input type="radio" [(ngModel)]="apiPlatform" id="wap" value="waapi">
                    <label for="wap">Waapi</label>
                </div>
                <div class="col-md-4 radio-field">
                    <input type="radio" [(ngModel)]="apiPlatform" id="chat" value="greenapi">
                    <label for="chat">GreenApi</label>
                </div>
                <div class="col-md-4 radio-field">
                    <input type="radio" [(ngModel)]="apiPlatform" id="mayt" value="maytapi">
                    <label for="mayt">MaytApi</label>
                </div>
                <!-- <div class="col-md-4 radio-field">
                <input type="radio" [(ngModel)]="apiPlatform" name="apiPlatform" [value]="3">
                <label>SMS App</label>
            </div> -->
            </div>
            <div class="row" style="margin-top: 1rem;justify-content: flex-end;padding: 0 15px;">
                <button *ngIf="!apiEdit" (click)="addApi()"
                    [disabled]="!token || !device_id || afterDelayTime < 0 || delayTime < 0 || limit < 0 || afterDelayTime == null  || delayTime == null || limit == null"
                    class="btn btn-primary" style="float: right; margin-top: 1rem;">Save</button>
                <button *ngIf="apiEdit" (click)="updateApi()"
                    [disabled]="!token || !device_id || afterDelayTime < 0 || delayTime < 0 || limit < 0 || afterDelayTime == null  || delayTime == null || limit == null"
                    class="btn btn-success" style="float: right; margin-top: 1rem;">Update</button>
            </div>
        </div>

        <div class="col-md-12" style="padding: 0 0 6rem 0; margin: 2rem auto;">
            <table class="table table-hover" id="dtBasicExample">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Device name</th>
                        <th>Token</th>
                        <th>Device ID</th>
                        <th>Delay time</th>
                        <th>Delay time</th>
                        <th>Limit</th>
                        <th colspan="3">Previous Day</th>
                        <th colspan="3">Current Day</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    <tr>
                        <th style="font-size: 0.7rem;" colspan="4"></th>
                        <th style="font-size: 0.7rem;">(min)</th>
                        <th style="font-size: 0.7rem;">(sec)</th>
                        <th style="font-size: 0.7rem;" colspan="1"></th>
                        <th style="font-size: 0.7rem;">Sent SMS</th>
                        <th style="font-size: 0.7rem;">Sent WA</th>
                        <th style="font-size: 0.7rem;">Sent Total</th>
                        <th style="font-size: 0.7rem;">Sent SMS</th>
                        <th style="font-size: 0.7rem;">Sent WA</th>
                        <th style="font-size: 0.7rem;">Sent Total</th>
                        <th style="font-size: 0.7rem;" colspan="2"></th>
                    </tr>
                </thead>
                <tbody *ngFor="let api of waApi; let i = index">
                    <tr>
                        <td>{{i+1}}</td>
                        <td>{{api.device_name}}</td>
                        <td>
                            <div class="text-overFlow">{{api.token}}</div>
                        </td>
                        <td>{{api.device_id}}</td>
                        <td *ngIf="api.delay_time % 1 == 0 ">{{api.delay_time}}m</td>
                        <td *ngIf="api.delay_time % 1 != 0 ">
                            {{api.delay_time.toString().split('.')[0]}}m
                            <span
                                *ngIf="(('0.'+api.delay_time.toString().split('.')[1])*60) % 1 == 0">{{(('0.'+api.delay_time.toString().split('.')[1])*60)}}s
                            </span>
                            <span
                                *ngIf="(('0.'+api.delay_time.toString().split('.')[1])*60) % 1 != 0">{{(('0.'+api.delay_time.toString().split('.')[1])*60).toFixed(2)}}s
                            </span>
                        </td>
                        <td>{{api.after_delay_time}}</td>
                        <td>{{api.limit}}</td>
                        <td *ngIf="api.total_last_calls - api.last_calls >= 0">{{api.total_last_calls - api.last_calls}}
                        </td>
                        <td *ngIf="api.total_last_calls - api.last_calls < 0">0</td>
                        <td>{{api.last_calls}}</td>
                        <td>{{api.total_last_calls}}</td>
                        <td *ngIf="api.total_calls - api.calls >= 0">{{api.total_calls - api.calls}}</td>
                        <td *ngIf="api.total_calls - api.calls < 0">0</td>
                        <td>{{api.calls}}</td>
                        <td>{{api.total_calls}}</td>
                        <td>
                            <label class="switch">
                                <input type="checkbox" [checked]="api.status" (change)="apiActivation($event, api, i)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                        <td>
                            <a class="btn" (click)="editApi(api, i)" style="padding: 2px; margin-right: 0.5rem;"><img
                                    style="height: 1.5rem;" src='../../../assets/icons/edit.png'></a>
                            <a class="btn" (click)="deleteApi(api, i)" style="padding: 2px;"><img
                                    style="height: 1.5rem;" src='../../../assets/icons/delete.png'></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="isLoad" class="loader-box">
    <span class="loader"></span>
</div>