<div class="modal-header" style="background-color: #009688;">
    <h4 class="modal-title" id="modal-title" style="color: #fff;font-size: 1.5rem;margin: 10px;">Vehicle Buttons Setting
    </h4>
    <button type="button" style="color: #fff; opacity: 1;" class="close" aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding: 2rem">
    <!-- <div class="col-md-12">
        <p style="color: gray;">How many seconds after the driver has arrived, would you like the customer not to be
            able to cancel their booking
            via WhatsApp and be asked to call instead?</p>
    </div> -->
    <div class="row" style="flex-wrap: nowrap;padding: 1rem 5px;background-color: #f6f6f6;border-radius: 5px;">
        <div class="col-md-6">
            <label style="color: #666666;">Button name:</label>
            <input class="form-control" [(ngModel)]="name1" type="text">
        </div>
        <div class="col-md-6">
            <label style="color: #666666;">Vehicle Type:</label>
            <input class="form-control" [(ngModel)]="type1" type="text">
        </div>
    </div>
    <div class="row"
        style="flex-wrap: nowrap;padding: 1rem 5px;background-color: #f6f6f6;border-radius: 5px;margin-top: 1rem;">
        <div class="col-md-6">
            <label style="color: #666666;">Button name:</label>
            <input class="form-control" [(ngModel)]="name2" type="text">
        </div>
        <div class="col-md-6">
            <label style="color: #666666;">Vehicle Type:</label>
            <input class="form-control" [(ngModel)]="type2" type="text">
        </div>
    </div>
    <div class="row"
        style="flex-wrap: nowrap;padding: 1rem 5px;background-color: #f6f6f6;border-radius: 5px;margin-top: 1rem;">
        <div class="col-md-6">
            <label style="color: #666666;">Button name:</label>
            <input class="form-control" [(ngModel)]="name3" type="text">
        </div>
        <div class="col-md-6">
            <label style="color: #666666;">Vehicle Type:</label>
            <input class="form-control" [(ngModel)]="type3" type="text">
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: center">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('true')">
        Cancel
    </button>
    <button type="button" class="btn btn-outline-success"
        [disabled]="(name1 && !type1) || (!name1 && type1) || (name2 && !type2) || (!name2 && type2) || (name3 && !type3) || (!name3 && type3)"
        (click)="saveSettings()">Save</button>
</div>