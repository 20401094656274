import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as componentObj from './components/index';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  { path: 'fb-bsp', component: componentObj.FbBspComponent },
  { path: 'qr-code', component: componentObj.GreenapiQrCodeComponent },
  { path: 'sessions/signin', component: componentObj.LoginComponent },
  { path: 'sessions/admin/signin', component: componentObj.AdminLoginComponent },

  { path: 'r/:id', component: componentObj.ReviewComponent },
  { path: 'dr/:id', component: componentObj.DriverReviewComponent },
  {
    path: 'bot',
    component: componentObj.DashboardComponent,
    children: [
      { path: 'broadcast', component: componentObj.BroadcastComponent },
      { path: 'my-broadcast', component: componentObj.MybroadcastComponent },
      { path: 'messages', component: componentObj.CustomMessagesComponent },
      { path: 'custom-messages', component: componentObj.BotMessagesComponent },
      { path: 'custom-values', component: componentObj.BotCustomValuesComponent },
      { path: 'blacklist-users', component: componentObj.BlacklistUserComponent },
    ]
  },
  {
    path: 'admin',
    component: componentObj.AdminDashboardComponent,
    children: [
      { path: 'users', component: componentObj.UsersListComponent },
      { path: 'account/:id', component: componentObj.AccountDetailsComponent },
    ]
  },
  { path: 'devices/:id', component: componentObj.DeviceListComponent },
  { path: 'admin/devices/:id', component: componentObj.AccountDevicesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
