import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BotService, AdminService } from './../../services';

@Component({
  selector: 'app-driver-review',
  templateUrl: './driver-review.component.html',
  styleUrls: ['./driver-review.component.scss']
})
export class DriverReviewComponent implements OnInit {

  constructor(
    private BS: BotService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    let id = this.activatedRoute.params['value'].id
    console.log(id);

    this.BS.getDriver(id).subscribe(data => {
      let gLink = data.webhook_data.Glink.replace('=ID', '=' + data.webhook_data.ID).replace('Driverref', data.webhook_data.Driverref).replace('phone', data.webhook_data.phone)
      window.location.replace(gLink)
    })
  }

}
