<div class="row" style="margin: 0px;">
    <div class="side-bar col-md-2">
        <div class="logo">
            <!-- <h4>Smart Messaging</h4> -->
            <img style="height: 100%;" src="../../../assets/images/logo.png">
        </div>
        <div class="side-bar-title">
            <a>
                <span style="color: #527396;text-transform: uppercase;">DEVICES</span>
            </a>
        </div>
        <div class="side-bar-item" (click)="openDevice(dev,i)" *ngFor="let dev of deviceList; let i = index;"
            [ngStyle]="{'background-color' : currentIndex == i ? '#1a756f' : 'transparent'}">
            <a>
                <img src="../../../assets/icons/whatsapp.png">
                <span>{{dev.device_name}}</span>
            </a>
        </div>
        <!-- <div class="side-bar-item" (click)="moveTo('usr')"
            [ngStyle]="{'background-color' : selected == 'usr' ? '#1a756f' : 'transparent'}">
            <a>
                <img src="../../../assets/icons/whatsapp.png">
                <span>WhatsApp #124323</span>
            </a>
        </div>
        <div class="side-bar-item" (click)="moveTo('usr')"
            [ngStyle]="{'background-color' : selected == 'usr' ? '#1a756f' : 'transparent'}">
            <a>
                <img src="../../../assets/icons/whatsapp.png">
                <span>WhatsApp #124323</span>
            </a>
        </div> -->
    </div>
    <div class="main col-md-10">
        <div class="navbar">
            <div style="height: 100%;display: flex;align-items: center;border-right: 2px solid #eaeaea;">
                <a (click)="back()"><img style="height: 1.5rem;margin: 1rem;" src="../../../assets/icons/back.png"></a>
            </div>
            <div>
                <div class="navbar-item dropdown">
                    <a class="profile"><img style="background-color: #fff;border-radius: 50px;padding: 3px;"
                            src="../../../assets/icons/profile.png">
                        <!-- <span style="text-transform: capitalize; margin-right: 5px; color: #fff;"></span> -->
                        <i class="fa fa-angle-down"></i>
                    </a>
                    <div class="dropdown-content">
                        <ul style="display: flex; flex-direction: column;">
                            <!-- <li>
                                <a class="list-item">Profile</a>
                            </li> -->
                            <li>
                                <a class="list-item" (click)="logout()">Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row page">
            <div class="col-md-12" style="padding: 0;">
                <div *ngIf="currentDevice" class="header">
                    <div *ngIf="currentDevice.wa_api_platform  == 'chatapi'" class="col-md-11" style="padding:24px;">
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6" style="display: flex;">
                                <img style="width: 32px;margin-right: 1rem;" src="../../../assets/icons/whatsapp.png">
                                <span class="device-name"><span
                                        style="font-weight: bold;">{{currentDevice.device_name}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="display: flex;justify-content: flex-end;">
                                <a class="refresh-tooltip" (click)="openDevice(currentDevice,currentIndex)">
                                    <img style="height: 25px;" src="../../../assets/icons/refresh.png">
                                    <span class="refresh-tooltiptext" style="left: -90%;">Refresh</span>
                                </a>
                                <a class="refresh-tooltip" (click)="logoutDevice(currentDevice,currentIndex)">
                                    <img style=" height: 25px;" src="../../../assets/icons/logout.png">
                                    <span class="refresh-tooltiptext" style="left: -90%;">Logout</span>
                                </a>
                                <a class="refresh-tooltip" (click)="rebootDevice(currentDevice,currentIndex)">
                                    <img style="height: 25px;" src="../../../assets/icons/reboot.png">
                                    <span class="refresh-tooltiptext" style="left: -140%;">Reboot<br>WhatsApp
                                        Session</span>
                                </a>
                                <!-- <a class="refresh-tooltip">
                                    <img style="height: 25px;" src="../../../assets/icons/cleaner.png">
                                    <span class="refresh-tooltiptext">Clear Instance</span>
                                </a> -->
                            </div>
                        </div>
                        <div class="col-md-12" style="padding-top: 15px;">
                            <div class="col-md-6">
                                <span style="color: #c6e7e4;">Auth status:</span>
                                <img *ngIf="authStatus == 'Authenticated'"
                                    style="height: 15px;margin: 0px 0.5rem 4px 0.5rem;"
                                    src="../../../assets/icons/green-checkmark.png">
                                <img *ngIf="authStatus != 'Authenticated'"
                                    style="height: 15px;margin: 0px 0.5rem 4px 0.5rem;"
                                    src="../../../assets/icons/circle.png">
                                <span style="color: #fff;font-weight: bold;">{{authStatus}}</span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-6">
                                <span style="color: #c6e7e4;">Connection:</span>
                                <span style="margin-left: 1rem;color: #fff;font-weight: bold;">{{connection}}</span>
                            </div>
                        </div>
                        <div class="col--md-12" style="padding: 15px;">
                            <div *ngIf="errMessage != '' || isLoad" class="col-md-8 qr-code-box">
                                <div style="width: 100%;padding: 1rem;margin-top: 2rem;">
                                    <div *ngIf="isLoad" class="loader row">
                                        <div class="text-center">
                                            <div class="loader1">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 *ngIf="errMessage != '' "
                                        style="text-align: center;margin-top: 2rem;color: #969696;">{{errMessage}}</h5>
                                </div>
                            </div>
                            <div *ngIf="qrShow && !isLoad" class="col-md-8 qr-code-box">
                                <div style="width: 60%;padding: 1rem;margin-top: 2rem;">
                                    <h5>To send and receive messages, you have to authorize our server like for WhatsApp
                                        Web.</h5>
                                    <ul style=" list-style: none;">
                                        <li>1. Open the WhatsApp app on your phone</li>
                                        <li>2. Press Settings->WhatsApp WEB and then plus</li>
                                        <li>3. Scan a code and wait a minute</li>
                                        <li>4. Keep your phone turned on and connected to the Internet</li>
                                        <li>QR code is valid only for 45 seconds. Message sennding will be available
                                            right after authorization.</li>
                                    </ul>
                                </div>
                                <div><img style="height: 100%;" [src]="qrSrc"></div>
                            </div>
                            <div *ngIf="!qrShow && !isLoad && authStatus == 'Authenticated' && connection =='Active'"
                                class="col-md-6 connection-status-box">
                                <div style="height: 100%;">
                                    <img style="height: 100%;padding-top: 20px;width: 200px;"
                                        src="../../../assets/images/wifi-signal.png">
                                </div>
                                <div style="padding: 1rem;margin-top: 2rem;padding-left: 2rem;">
                                    <h4>Keep your phone connected</h4>
                                    <span style="font-size: 0.8rem;color: #808080;">WhatsApp connects to your phone to
                                        sync messages.
                                        To reduce data usage,
                                        connect your phone to Wi-Fi.</span>
                                </div>
                            </div>
                            <div *ngIf="!qrShow && !isLoad && authStatus == 'Authenticated' && connection =='Inactive'"
                                class="col-md-6 connection-error-box">
                                <div class="error-icon">
                                    <img style="height: 70%;padding-top: 20px;"
                                        src="../../../assets/icons/sync-error.png">
                                </div>
                                <div style="padding: 1rem;margin-top: 2rem;padding-left: 0;margin-left: -2rem;">
                                    <h4>Phone not connected</h4>
                                    <span style="font-size: 0.8rem;color: #0000008a;">
                                        Retrying...
                                        <br>
                                        Make sure your phone has an active Internet connection.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentDevice.wa_api_platform  == 'maytapi'" class="col-md-11" style="padding:24px;">
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6" style="display: flex;">
                                <img style="width: 32px;margin-right: 1rem;" src="../../../assets/icons/whatsapp.png">
                                <span class="device-name"><span
                                        style="font-weight: bold;">{{currentDevice.device_name}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="display: flex;justify-content: flex-end;">
                                <a class="refresh-tooltip" (click)="openMaytApi(currentDevice,currentIndex)">
                                    <img style="height: 25px;" src="../../../assets/icons/refresh.png">
                                    <span class="refresh-tooltiptext" style="left: -90%;">Refresh</span>
                                </a>
                                <a class="refresh-tooltip" (click)="logoutMaytApi(currentDevice,currentIndex)">
                                    <img style=" height: 25px;" src="../../../assets/icons/logout.png">
                                    <span class="refresh-tooltiptext" style="left: -90%;">Logout</span>
                                </a>
                                <a class="refresh-tooltip" (click)="rebootMaytApi(currentDevice,currentIndex)">
                                    <img style="height: 25px;" src="../../../assets/icons/reboot.png">
                                    <span class="refresh-tooltiptext" style="left: -140%;">Reboot<br>WhatsApp
                                        Session</span>
                                </a>
                                <!-- <a class="refresh-tooltip">
                                    <img style="height: 25px;" src="../../../assets/icons/cleaner.png">
                                    <span class="refresh-tooltiptext">Clear Instance</span>
                                </a> -->
                            </div>
                        </div>
                        <div class="col-md-12" style="padding-top: 15px;">
                            <div class="col-md-6">
                                <span style="color: #c6e7e4;">Auth status:</span>
                                <img *ngIf="authStatus == 'Authenticated'"
                                    style="height: 15px;margin: 0px 0.5rem 4px 0.5rem;"
                                    src="../../../assets/icons/green-checkmark.png">
                                <img *ngIf="authStatus != 'Authenticated'"
                                    style="height: 15px;margin: 0px 0.5rem 4px 0.5rem;"
                                    src="../../../assets/icons/circle.png">
                                <span style="color: #fff;font-weight: bold;">{{authStatus}}</span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-6">
                                <span style="color: #c6e7e4;">Connection:</span>
                                <span style="margin-left: 1rem;color: #fff;font-weight: bold;">{{connection}}</span>
                            </div>
                        </div>
                        <div class="col--md-12" style="padding: 15px;">
                            <div *ngIf="errMessage != '' || isLoad" class="col-md-8 qr-code-box">
                                <div style="width: 100%;padding: 1rem;margin-top: 2rem;">
                                    <div *ngIf="isLoad" class="loader row">
                                        <div class="text-center">
                                            <div class="loader1">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 *ngIf="errMessage != '' "
                                        style="text-align: center;margin-top: 2rem;color: #969696;">{{errMessage}}</h5>
                                </div>
                            </div>
                            <div *ngIf="qrShow && !isLoad" class="col-md-8 qr-code-box">
                                <div style="width: 60%;padding: 1rem;margin-top: 2rem;">
                                    <h5>To send and receive messages, you have to authorize our server like for WhatsApp
                                        Web.</h5>
                                    <ul style=" list-style: none;">
                                        <li>1. Open the WhatsApp app on your phone</li>
                                        <li>2. Press Settings->WhatsApp WEB and then plus</li>
                                        <li>3. Scan a code and wait a minute</li>
                                        <li>4. Keep your phone turned on and connected to the Internet</li>
                                        <li>QR code is valid only for 45 seconds. Message sennding will be available
                                            right after authorization.</li>
                                    </ul>
                                </div>
                                <div style="padding: 1rem;"><img style="height: 100%;" [src]="qrSrc"></div>
                            </div>
                            <div *ngIf="!qrShow && !isLoad && authStatus == 'Authenticated' && connection =='Active'"
                                class="col-md-6 connection-status-box">
                                <div style="height: 100%;">
                                    <img style="height: 100%;padding-top: 20px;width: 200px;"
                                        src="../../../assets/images/wifi-signal.png">
                                </div>
                                <div style="padding: 1rem;margin-top: 2rem;padding-left: 2rem;">
                                    <h4>Keep your phone connected</h4>
                                    <span style="font-size: 0.8rem;color: #808080;">WhatsApp connects to your phone to
                                        sync messages.
                                        To reduce data usage,
                                        connect your phone to Wi-Fi.</span>
                                </div>
                            </div>
                            <div *ngIf="!qrShow && !isLoad && authStatus == 'Authenticated' && connection =='Inactive'"
                                class="col-md-6 connection-error-box">
                                <div class="error-icon">
                                    <img style="height: 70%;padding-top: 20px;"
                                        src="../../../assets/icons/sync-error.png">
                                </div>
                                <div style="padding: 1rem;margin-top: 2rem;padding-left: 0;margin-left: -2rem;">
                                    <h4>Phone not connected</h4>
                                    <span style="font-size: 0.8rem;color: #0000008a;">
                                        Retrying...
                                        <br>
                                        Make sure your phone has an active Internet connection.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="currentDevice.wa_api_platform  == 'greenapi'" class="col-md-11" style="padding:24px;">
                        <div class="col-md-12" style="display: flex;">
                            <div class="col-md-6" style="display: flex;">
                                <img style="width: 32px;margin-right: 1rem;" src="../../../assets/icons/whatsapp.png">
                                <span class="device-name"><span
                                        style="font-weight: bold;">{{currentDevice.device_name}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="display: flex;justify-content: flex-end;">
                                <a class="refresh-tooltip" (click)="openGreenApi(currentDevice,currentIndex)">
                                    <img style="height: 25px;" src="../../../assets/icons/refresh.png">
                                    <span class="refresh-tooltiptext" style="left: -90%;">Refresh</span>
                                </a>
                                <a class="refresh-tooltip" (click)="logoutGreenApi(currentDevice,currentIndex)">
                                    <img style=" height: 25px;" src="../../../assets/icons/logout.png">
                                    <span class="refresh-tooltiptext" style="left: -90%;">Logout</span>
                                </a>
                                <a class="refresh-tooltip" (click)="rebootGreenApi(currentDevice,currentIndex)">
                                    <img style="height: 25px;" src="../../../assets/icons/reboot.png">
                                    <span class="refresh-tooltiptext" style="left: -140%;">Reboot<br>WhatsApp
                                        Session</span>
                                </a>
                                <!-- <a class="refresh-tooltip">
                                    <img style="height: 25px;" src="../../../assets/icons/cleaner.png">
                                    <span class="refresh-tooltiptext">Clear Instance</span>
                                </a> -->
                            </div>
                        </div>
                        <div class="col-md-12" style="padding-top: 15px;">
                            <div class="col-md-6">
                                <span style="color: #c6e7e4;">Auth status:</span>
                                <img *ngIf="authStatus == 'Authenticated'"
                                    style="height: 15px;margin: 0px 0.5rem 4px 0.5rem;"
                                    src="../../../assets/icons/green-checkmark.png">
                                <img *ngIf="authStatus != 'Authenticated'"
                                    style="height: 15px;margin: 0px 0.5rem 4px 0.5rem;"
                                    src="../../../assets/icons/circle.png">
                                <span style="color: #fff;font-weight: bold;">{{authStatus}}</span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-6">
                                <span style="color: #c6e7e4;">Connection:</span>
                                <span style="margin-left: 1rem;color: #fff;font-weight: bold;">{{connection}}</span>
                            </div>
                        </div>
                        <div class="col--md-12" style="padding: 15px;">
                            <div *ngIf="errMessage != '' || isLoad" class="col-md-8 qr-code-box">
                                <div style="width: 100%;padding: 1rem;margin-top: 2rem;">
                                    <div *ngIf="isLoad" class="loader row">
                                        <div class="text-center">
                                            <div class="loader1">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 *ngIf="errMessage != '' "
                                        style="text-align: center;margin-top: 2rem;color: #969696;">{{errMessage}}</h5>
                                </div>
                            </div>
                            <div *ngIf="qrShow && !isLoad" class="col-md-8 qr-code-box">
                                <div style="width: 60%;padding: 1rem;margin-top: 2rem;">
                                    <h5>To send and receive messages, you have to authorize our server like for WhatsApp
                                        Web.</h5>
                                    <ul style=" list-style: none;">
                                        <li>1. Open the WhatsApp app on your phone</li>
                                        <li>2. Press Settings->WhatsApp WEB and then plus</li>
                                        <li>3. Scan a code and wait a minute</li>
                                        <li>4. Keep your phone turned on and connected to the Internet</li>
                                        <li>QR code is valid only for 45 seconds. Message sennding will be available
                                            right after authorization.</li>
                                    </ul>
                                </div>
                                <div style="padding: 1rem;"><img style="height: 100%;" [src]="qrSrc"></div>
                            </div>
                            <div *ngIf="!qrShow && !isLoad && authStatus == 'Authenticated' && connection =='Active'"
                                class="col-md-6 connection-status-box">
                                <div style="height: 100%;">
                                    <img style="height: 100%;padding-top: 20px;width: 200px;"
                                        src="../../../assets/images/wifi-signal.png">
                                </div>
                                <div style="padding: 1rem;margin-top: 2rem;padding-left: 2rem;">
                                    <h4>Keep your phone connected</h4>
                                    <span style="font-size: 0.8rem;color: #808080;">WhatsApp connects to your phone to
                                        sync messages.
                                        To reduce data usage,
                                        connect your phone to Wi-Fi.</span>
                                </div>
                            </div>
                            <div *ngIf="!qrShow && !isLoad && authStatus == 'Authenticated' && connection =='Inactive'"
                                class="col-md-6 connection-error-box">
                                <div class="error-icon">
                                    <img style="height: 70%;padding-top: 20px;"
                                        src="../../../assets/icons/sync-error.png">
                                </div>
                                <div style="padding: 1rem;margin-top: 2rem;padding-left: 0;margin-left: -2rem;">
                                    <h4>Phone not connected</h4>
                                    <span style="font-size: 0.8rem;color: #0000008a;">
                                        Retrying...
                                        <br>
                                        Make sure your phone has an active Internet connection.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" style="margin-top: 15rem;">
                    <div class="graph-box" style="display: flex;">
                        <!-- 
                        <div class="row" style="margin: 0px;">
                            <div class="col-md-6 filter-heading" style="border: none;">Graph</div>
                        </div> -->
                        <div *ngIf="isGraphLoad" class="chart-wrapper col-md-8" style="margin: auto;">
                            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels"
                                [colors]="barChartColors" [options]="barChartOptions" [plugins]="barChartPlugins"
                                [legend]="barChartLegend" [chartType]="barChartType">
                            </canvas>
                        </div>
                        <div *ngIf="isGraphLoad" class="col-md-2"
                            style="display: flex;flex-direction: column;justify-content: space-between;margin-bottom: 15px;">
                            <div class="matrix-box-title">
                                <label>WhatsApp</label>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">Today</label>
                                <span>{{waToday}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">15 Minutes</label>
                                <span>{{wa15Min}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">30 Minutes</label>
                                <span>{{wa30Min}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">60 Minutes</label>
                                <span>{{wa60Min}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">06 Hours</label>
                                <span>{{wa6Hr}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">12 Hours</label>
                                <span>{{wa12Hr}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">24 Hours</label>
                                <span>{{wa24Hr}}</span>
                            </div>
                        </div>
                        <div *ngIf="isGraphLoad" class="col-md-2"
                            style="display: flex;flex-direction: column;justify-content: space-between;margin-bottom: 15px;">
                            <div class="matrix-box-title">
                                <label>SMS</label>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">Today</label>
                                <span>{{smsToday}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">15 Minutes</label>
                                <span>{{sms15Min}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">30 Minutes</label>
                                <span>{{sms30Min}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">60 Minutes</label>
                                <span>{{sms60Min}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">06 Hours</label>
                                <span>{{sms6Hr}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">12 Hours</label>
                                <span>{{sms12Hr}}</span>
                            </div>
                            <div class="matrix-box">
                                <label style="color: gray;">24 Hours</label>
                                <span>{{sms24Hr}}</span>
                            </div>
                        </div>
                        <div *ngIf="!isGraphLoad" class="loader row">
                            <div class="text-center">
                                <div class="loader1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="msgTable">
                        <div class="row" style="margin: 0px;">
                            <div class="col-md-12 filter-heading">Message to Driver</div>
                        </div>
                        <div class="row" style="margin: 0px;padding: 1.5rem 0.5rem;">
                            <div *ngIf="isGraphLoad" class="col-md-12 search-field"
                                style="border: none;display: flex;flex-wrap: nowrap;">
                                <label class="col-md-2 driver-msg-stats">Track</label>
                                <label class="col-md-2 driver-msg-stats">Duration: 1 week</label>
                                <label class="col-md-2 driver-msg-stats">Total: {{trackDriverTotalMsg}}</label>
                                <label class="col-md-2 driver-msg-stats">Yes: {{trackDriverYesMsg}}</label>
                                <label class="col-md-2 driver-msg-stats">No: {{trackDriverTotalMsg -
                                    trackDriverYesMsg}}</label>
                            </div>
                            <div *ngIf="isGraphLoad" class="col-md-12 search-field"
                                style="border: none;display: flex;flex-wrap: nowrap;">
                                <label class="col-md-2 driver-msg-stats">Arrived</label>
                                <label class="col-md-2 driver-msg-stats">Duration: 1 week</label>
                                <label class="col-md-2 driver-msg-stats">Total: {{arrivedDriverTotalMsg}}</label>
                                <label class="col-md-2 driver-msg-stats">Yes: {{arrivedDriverYesMsg}}</label>
                                <label class="col-md-2 driver-msg-stats">No: {{arrivedDriverTotalMsg -
                                    arrivedDriverYesMsg}}</label>
                            </div>
                            <div *ngIf="!isGraphLoad" class="loader row">
                                <div class="text-center">
                                    <div class="loader1">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="msgTable">
                        <div class="row" style="margin: 0px;">
                            <div class="col-md-12 filter-heading">Bookings</div>
                        </div>
                        <div class="row" style="margin: 0px;padding: 1.5rem 0.5rem;">
                            <div *ngIf="isGraphLoad" class="col-md-12 search-field"
                                style="border: none;display: flex;flex-wrap: nowrap;">
                                <label class="col-md-2 driver-msg-stats">Duration: 1 week</label>
                                <label class="col-md-2 driver-msg-stats">Total: {{totalBooking}}</label>
                                <label class="col-md-2 driver-msg-stats">Cancelled: {{cancelledBooking}}</label>
                                <label class="col-md-2 driver-msg-stats">Confirmed: {{totalBooking -
                                    cancelledBooking}}</label>
                            </div>
                            <div *ngIf="!isGraphLoad" class="loader row">
                                <div class="text-center">
                                    <div class="loader1">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="msgTable">
                        <div *ngIf="!showFilter" class="row" style="margin: 0px;">
                            <div class="col-md-6 filter-heading" style="border: none;">Filters</div>
                            <div class="col-md-6 filter-heading" style="justify-content: flex-end;border: none;">
                                <button class="btn btn-primary" (click)="filters()">Show filters</button>
                            </div>
                        </div>
                        <div *ngIf="showFilter" class="row" style="margin: 0px;">
                            <div class="col-md-6 filter-heading">Filters</div>
                            <div class="col-md-6 filter-heading" style="justify-content: flex-end;">
                                <button class="btn btn-primary" (click)="filters()">Hide filters</button>
                            </div>
                        </div>
                        <div *ngIf="showFilter" class="row" style="margin: 0;padding: 1.5rem 0.5rem;">
                            <div class="col-md-4 search-field">
                                <label>Target</label>
                                <input class="form-control" [(ngModel)]="target" type="text">
                            </div>
                            <div class="col-md-4 search-field">
                                <label>Target type</label>
                                <select class="form-control" [(ngModel)]="target_type">
                                    <option value="whatsapp">WhatsApp</option>
                                    <option value="non_whatsapp">Sms</option>
                                </select>
                            </div>
                            <div class="col-md-4 search-field">
                                <label>Status</label>
                                <select class="form-control" [(ngModel)]="status">
                                    <option value="Sent">Sent</option>
                                    <option value="Failed">Failed</option>
                                </select>
                            </div>
                            <div class="col-md-4 search-field">
                                <label>Sent via</label>
                                <select class="form-control" [(ngModel)]="sent_by">
                                    <option value="1">WhatsApp</option>
                                    <option value="0">Sms</option>
                                    <option value="2">None</option>
                                </select>
                            </div>
                            <div class="col-md-4 search-field">
                                <label>From</label>
                                <input class="form-control" [(ngModel)]="f_date" type="date">
                            </div>
                            <div class="col-md-4 search-field">
                                <label>To</label>
                                <input class="form-control" [(ngModel)]="t_date" type="date">
                            </div>
                            <div class="col-md-12 search-field" style="text-align: end;margin-top: 1.5rem;">
                                <button class="btn btn-outline-secondary" (click)="clean()">Clear</button>
                                <button class="btn btn-primary" (click)="search()">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" style="margin-top: 1rem;">
                    <div class="msgTable">
                        <table *ngIf="!isMsgLoad" class="table" id="dtBasicExample">
                            <tbody>
                                <tr>
                                    <td class="top-row" colspan="7">
                                        <div class="row" style="margin: 0px;">
                                            <div class="col-md-6 filter-heading" style="border: none;">
                                                Messages (showing
                                                <span
                                                    *ngIf="messageList.length > 0">{{currentPageLimit+1}}-{{currentPageLimit+messageList.length}}</span>
                                                <span *ngIf="!messageList.length">0</span>
                                                )
                                            </div>
                                            <div class="col-md-6 filter-heading"
                                                style="justify-content: flex-end;border: none;">
                                                <button class="btn btn-primary"
                                                    (click)="openDevice(currentDevice, currentIndex)">Refresh</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <!-- <td class="second-row"></td> -->
                                    <td class="second-row">CREATED AT</td>
                                    <td class="second-row">TARGET TYPE</td>
                                    <td class="second-row">TARGET</td>
                                    <td class="second-row">MESSAGE TYPE</td>
                                    <td class="second-row">SENT VIA</td>
                                    <td class="second-row">STATUS</td>
                                    <td class="second-row" style="text-align: center;">MESSAGE</td>
                                    <!-- <td class="second-row"></td> -->
                                </tr>
                            </tbody>
                            <tbody *ngFor="let msg of messageList; let i = index">
                                <tr>
                                    <!-- <td class="first-cell"><a><img style="height:1.5rem;"
                                                src="../../../assets/icons/eye.png"></a></td> -->
                                    <td>{{msg.createdAt | date:'medium'}}</td>
                                    <td>
                                        <div *ngIf="msg.target_type == 'whatsapp'" class="dot-box">
                                            <span class="green-dot"></span>WhatsApp
                                        </div>
                                        <div *ngIf="msg.target_type == 'non_whatsapp'" class="dot-box">
                                            <span class="blue-dot"></span>SMS
                                        </div>
                                    </td>
                                    <td style="color: #1a756f;">+{{msg.to_number}}
                                        <a style="margin: 0;text-decoration: none;" class="refresh-msg-tooltip"
                                            *ngIf="msg.driver_msg">💬
                                            <span class="refresh-msg-tooltiptext">{{msg.driver_msg}}</span>
                                        </a>
                                    </td>
                                    <td style="text-transform: capitalize;">{{msg.msg_type}}</td>
                                    <td>
                                        <div *ngIf="msg.sent_by == 0" class="dot-box">
                                            <span class="blue-dot"></span>SMS
                                        </div>
                                        <div *ngIf="msg.sent_by == 1" class="dot-box">
                                            <span class="green-dot"></span>WhatsApp
                                        </div>
                                        <div *ngIf="msg.sent_by == 2" class="dot-box">
                                            <span class="red-dot"></span>None
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngIf="msg.status == 'Sent'" class="dot-box">
                                            <span class="green-dot"></span>Sent
                                        </div>
                                        <div *ngIf="msg.status == 'Failed'" class="dot-box">
                                            <span class="red-dot"></span>Failed
                                        </div>
                                    </td>
                                    <td class="refresh-msg-tooltip">
                                        <div class="msg-block">{{msg.message_text}}</div>
                                        <span class="refresh-msg-tooltiptext">{{msg.message_text}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="isMsgLoad" class="loader row">
                            <div class="text-center">
                                <div class="loader1">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isMsgLoad" class="pagination">
                            <a class="active" *ngIf="page > 1" (click)="previousPage()">Prev &laquo;</a>
                            <a *ngIf="messageList.length > 0" class="active" (click)="nextPage()">Next
                                &raquo;</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div *ngIf="isLoad" class="loader row">
    <div class="text-center">
        <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div> -->