<div class="modal-header" style="background-color: #009688;">
    <h4 class="modal-title" id="modal-title" style="color: #fff;">Missed Call Message Details</h4>
    <button type="button" style="color: #fff; opacity: 1;" class="close" aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding: 3rem 5rem">
    <div class="col-md-12">
        <p style="color: gray;">Enter App link from where user can download the company App and also enter the phone
            number user can call back on.</p>
    </div>
    <div class="col-md-12">
        <label>App Url:</label>
        <input class="form-control" [(ngModel)]="missedcall_url" type="text">
    </div>
    <div class="col-md-12">
        <label>Call Number:</label>
        <input class="form-control" [(ngModel)]="missedcall_phone" type="text">
    </div>
</div>
<div class="modal-footer" style="justify-content: center">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('true')">
        Cancel
    </button>
    <button type="button" class="btn btn-outline-success" (click)="saveValue()">Save</button>
</div>