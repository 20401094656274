import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from './../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  user_name: string = '';
  phone: string = '';
  isLoad = true;
  userList = [];
  spUserList = [];
  regUserList = [];
  userView = 'all'
  constructor(
    private BS: BotService,
    private AS: AdminService,
    private router: Router
  ) { }


  ngOnInit() {
    this.AS.getCurrentAdminFromBack().subscribe(() => {

      let currentUser = JSON.parse(localStorage.getItem('SMSADM'));
      if (!currentUser) {
        this.router.navigateByUrl('/sessions/admin/signin');
      }
      this.BS.getUser().subscribe(result => {
        // this.userList = result.sort((a, b) => a.name.length - b.name.length|| a.name.localeCompare(b.name));
        this.userList = result.sort(this.customComparator)
        this.isLoad = false;
      })
    })
  }

  customComparator(a, b) {
    // Extract numbers from the end of the strings (if present)
    const numA = parseInt(a.name.match(/\d+$/)) || 0;
    const numB = parseInt(b.name.match(/\d+$/)) || 0;

    // Compare the words part first
    const wordA = a.name.replace(/\d+$/, '');
    const wordB = b.name.replace(/\d+$/, '');

    if (wordA < wordB) return -1;
    if (wordA > wordB) return 1;

    // If the words part is the same, compare the numbers
    return numA - numB;
  }

  showUserDetails(user, i) {
    this.router.navigateByUrl(`/admin/account/${user._id}`);
  }

  openDevices(user, i) {
    this.router.navigateByUrl(`/admin/devices/${user._id}`);
  }

  userActivation(e, user, i) {
    let obj = {
      _id: user._id,
      active: e.target.checked
    }
    // console.log(obj);

    this.AS.updateUser(obj).subscribe(result => {
      // console.log(result);

    })
  }

  // deleteUser(index) {
  //   this.BS.deleteSpecialNumber(this.userList[index]._id).subscribe(result => {
  //     this.userList.splice(index, 1);
  //   })
  // }

  view(v) {
    this.userView = v
  }


}
