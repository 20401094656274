import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from 'ngx-chips';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NgxPaginationModule } from 'ngx-pagination';

import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { AccountDevicesComponent } from './components/account-devices/account-devices.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { BlacklistUserComponent } from './components/blacklist-user/blacklist-user.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CustomMessagesComponent } from './components/custom-messages/custom-messages.component';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { DriverReviewComponent } from './components/driver-review/driver-review.component';
import { MessageSettingsComponent } from './components/message-settings/message-settings.component';
import { ReviewComponent } from './components/review/review.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { LoginComponent } from './components/login/login.component';
import { SearchPhonePipe } from './pipes/search-phone.pipe';
import { BookingConfirmationComponent } from './components/booking-confirmation/booking-confirmation.component';
import { MissedcallSettingsComponent } from './components/missedcall-settings/missedcall-settings.component';
import { GreenapiQrCodeComponent } from './components/greenapi-qr-code/greenapi-qr-code.component';
import { BotMessagesComponent } from './components/bot-messages/bot-messages.component';
import { BotCustomValuesComponent } from './components/bot-custom-values/bot-custom-values.component';
import { VehicleTypeSettingsComponent } from './components/vehicle-type-settings/vehicle-type-settings.component';
import { BroadcastComponent } from './components/broadcast/broadcast.component';
import { MybroadcastComponent } from './components/mybroadcast/mybroadcast.component';
import { DriverMessageComponent } from './components/driver-message/driver-message.component';
import { FbBspComponent } from './components/fb-bsp/fb-bsp.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLoginComponent,
    DashboardComponent,
    AccountDetailsComponent,
    AccountDevicesComponent,
    AdminDashboardComponent,
    BlacklistUserComponent,
    ConfirmationModalComponent,
    CustomMessagesComponent,
    DeviceListComponent,
    DriverReviewComponent,
    MessageSettingsComponent,
    ReviewComponent,
    UsersListComponent,
    LoginComponent,
    SearchPhonePipe,
    BookingConfirmationComponent,
    MissedcallSettingsComponent,
    GreenapiQrCodeComponent,
    BotMessagesComponent,
    BotCustomValuesComponent,
    VehicleTypeSettingsComponent,
    BroadcastComponent,
    MybroadcastComponent,
    DriverMessageComponent,
    FbBspComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TagInputModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    FormsModule,
    ChartsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    BookingConfirmationComponent,
    MissedcallSettingsComponent,
    ConfirmationModalComponent,
    MessageSettingsComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
