<div class="container main">

    <div class="formargin">

    </div>
    <div class="forheading row">
        <div class="col-md-6" style="display: flex;align-items: center;">
            <h1>My BroadCast</h1>
        </div>
        <div class="col-md-6 forButton">
            <a (click)="addBroadcast()">Add Broadcast</a>
        </div>
    </div>

    <div class="for_table_heading">
        <div class="heading" style="width: 10%;">
            <h6>Date&Time</h6>
        </div>
        <div class="heading" style="width: 15%;">
            <h6>Name</h6>
        </div>
        <div class="heading" style="width: 15%;">
            <h6>Contacts</h6>
        </div>
        <div class="heading" style="width: 10%;">
            <h6>Delay</h6>
        </div>
        <div class="heading" style="width: 15%;">
            <h6>Message</h6>
        </div>
        <div class="heading" style="width: 15%;">
            <h6 style="text-align: center;">Status</h6>
        </div>
        <div class="heading" style="width: 20%;">
            <h6 style="text-align: center;">Action</h6>
        </div>
    </div>
    <div *ngFor="let broadcast of broadcastList;let i = index;" class="forbody">
        <div class="heading" style="width: 10%;">
            <span class="forspan" style="font-weight: 500;">{{broadcast.createdAt |
                date}}<br>{{broadcast.createdAt |
                date:"hh:mm"}}</span>
        </div>
        <div class="heading" style="width: 15%;">
            <span class="forspan">{{broadcast.broadcast_name}}</span>
        </div>
        <div class="refresh-msg-tooltip" style="width: 15%;">
            <span class="forspan">Contacts</span>
            <div class="refresh-msg-tooltiptext">
                <p>
                    <span *ngFor="let contact of broadcast.contacts;let j = index;"> [{{contact}}]
                    </span>
                </p>
            </div>
        </div>
        <div class="heading" style="width: 10%;">
            <span class="forspan">{{broadcast.delay_time}} sec</span>
        </div>
        <div class="refresh-msg-tooltip" style="width: 15%;">
            <span class="forspan">{{broadcast.message_body}}</span>
            <span class="refresh-msg-tooltiptext">{{broadcast.message_body}}</span>
        </div>
        <div class="heading" style="width: 15%;" style="justify-content: center;">
            <p *ngIf="broadcast.status == 'complete'" class="foractive" style="margin: 0px;">
                {{broadcast.status}}</p>
            <p *ngIf="broadcast.status == 'processing'" class="foractive"
                style="margin: 0px;background-color: rgb(0, 215, 230);">
                {{broadcast.status}}</p>
            <p *ngIf="broadcast.status == 'pending'" class="foractive"
                style="margin: 0px;background-color: rgb(255, 204, 0);">
                {{broadcast.status}}</p>
            <p *ngIf="broadcast.status == 'paused'" class="foractive"
                style="margin: 0px;background-color: rgb(255, 0, 0);">
                Terminate</p>
        </div>
        <div class="action-box" style="width: 20%;">
            <button class="btn btn-outline-secondary action-btn"
                [disabled]="broadcast.status != 'pending' && broadcast.status !='processing'"
                (click)="terminateBroadcast(i,broadcast)">Terminate</button>
            <button class="btn btn-outline-danger action-btn" (click)="deleteBroadcast(i,broadcast)">Delete</button>
        </div>
    </div>
</div>