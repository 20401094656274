<div *ngIf="!isLoad" class="row">
    <div class="sidebar col-md-4">
        <!-- <div class="setting-box">
            <a style="width: fit-content; font-weight: bold;">Message to driver (type Track)</a>
            <label class="switch">
                <input type="checkbox" (change)="setTrackDriverMsg()" [(ngModel)]="isTrackDriverMsg">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="setting-box">
            <a style="width: fit-content; font-weight: bold;">Message to driver (type Arrived)</a>
            <label class="switch">
                <input type="checkbox" (change)="setArrivedDriverMsg()" [(ngModel)]="isArrivedDriverMsg">
                <span class="slider round"></span>
            </label>
        </div> -->
        <div class="setting-box">
            <a style="width: fit-content; font-weight: bold;">Allow message to drive (on/off)</a>
            <div style="display: flex;align-items: center;">
                <a (click)="setDriverMessages()" style="margin-right: 8px;"><img style="height:1.5rem"
                        src="../../../assets/icons/setting.png"></a>

                <label class="switch">
                    <input type="checkbox" (change)="setArrivedDriverMsg()" [(ngModel)]="isArrivedDriverMsg">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="setting-box">
            <a style="width: fit-content; font-weight: bold;">Allow booking
                cancellation (on/off)</a>
            <div style="display: flex;align-items: center;">
                <a (click)="setBookingConfirmationDetails()" style="margin-right: 8px;"><img style="height:1.5rem"
                        src="../../../assets/icons/setting.png"></a>

                <label class="switch">
                    <input type="checkbox" (change)="setBookingConfirmation()" [(ngModel)]="isBookingConfirmation">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
        <div class="setting-box" (click)="openMissedCallSettings()">
            <a style="width: fit-content; font-weight: bold;">Missed Call Message Settings</a>
        </div>
        <div class="setting-box" (click)="openSettings()">
            <a style="width: fit-content; font-weight: bold;">Settings</a>
        </div>
        <div class="setting-box" (click)="callResponseSetting()">
            <a style="width: fit-content; font-weight: bold;">Call Response</a>
        </div>
        <div class="msg-box" *ngFor="let msg of booking_msg; let i = index" (click)="openMessage('booking',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Booking Message
                {{i+1}} <span *ngIf="i == booking_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of late_msg; let i = index" (click)="openMessage('late',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Late Message
                {{i+1}} <span *ngIf="i == late_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of tracking_msg; let i = index" (click)="openMessage('tracking',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Tracking Message
                {{i+1}} <span *ngIf="i == tracking_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of arrived_msg; let i = index" (click)="openMessage('arrived',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Arrived Messages
                {{i+1}} <span *ngIf="i == arrived_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of paylink_msg; let i = index" (click)="openMessage('paylink',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Paylink Messages
                {{i+1}} <span *ngIf="i == paylink_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of prepay_msg; let i = index" (click)="openMessage('prepay',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Prepay Messages
                {{i+1}} <span *ngIf="i == prepay_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of review_msg; let i = index" (click)="openMessage('review',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Review Messages
                {{i+1}} <span *ngIf="i == review_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of dreview_msg; let i = index" (click)="openMessage('dreview',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Driver Review Messages
                {{i+1}} <span *ngIf="i == dreview_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of preauth_msg; let i = index" (click)="openMessage('preauth',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Pre Auth Messages
                {{i+1}} <span *ngIf="i == preauth_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of custom1_msg; let i = index" (click)="openMessage('custom1',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Custom 1 Messages
                {{i+1}} <span *ngIf="i == custom1_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of custom2_msg; let i = index" (click)="openMessage('custom2',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Custom 2 Messages
                {{i+1}} <span *ngIf="i == custom2_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of chatbot_msg; let i = index" (click)="openMessage('chatbot',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Chatbot Message {{i+1}}</a>
        </div>
        <div class="msg-box" *ngFor="let msg of missedcall_msg; let i = index"
            (click)="openMessage('missedcall',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Missed Call Messages
                {{i+1}} <span *ngIf="i == missedcall_msg.length - 1">(SMS)</span></a>
        </div>
        <div class="msg-box" *ngFor="let msg of noshow_msg; let i = index" (click)="openMessage('noshow',i,msg)">
            <a style="width: fit-content; font-weight: bold;">Noshow Messages {{i+1}}</a>
        </div>
    </div>
    <div class="main col-md-8">
        <h2 *ngIf="adminDetails" style="margin-bottom: 1rem;">{{adminDetails.name}}</h2>
        <div>
            <div class="msg-box">
                <div *ngIf="currentList == 'review'" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Review Message limit </h6>
                    <input [disabled]="isEdit" class="form-control" [(ngModel)]="review_limit" type="number">
                </div>
                <div *ngIf="currentList == 'preauth'" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Pre Auth Message limit </h6>
                    <input [disabled]="isEdit" class="form-control" [(ngModel)]="preAuth_limit" type="number">
                </div>
                <div *ngIf="currentList == 'custom1'" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Custom1 limit </h6>
                    <input [disabled]="isEdit" class="form-control" [(ngModel)]="custom1_limit" type="number">
                </div>
                <div *ngIf="currentList == 'custom2'" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Custom2 limit </h6>
                    <input [disabled]="isEdit" class="form-control" [(ngModel)]="custom2_limit" type="number">
                </div>
                <div *ngIf="currentList == 'call_response' && !isEdit" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Call Response</h6>
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="isCallResponse">
                        <span class="slider round"></span>
                    </label>
                </div>
                <!-- <div *ngIf="currentList == 'track_driver_msg' && !isEdit" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Track Driver Message</h6>
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="isTrackDriverMsg">
                        <span class="slider round"></span>
                    </label>
                </div>

                <div *ngIf="currentList == 'arrived_driver_msg' && !isEdit" class="col-md-12" style="padding: 1rem 0;">
                    <h6>Arrived Driver Message</h6>
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="isArrivedDriverMsg">
                        <span class="slider round"></span>
                    </label>
                </div> -->

                <h6 *ngIf="currentList == 'call_response'"><span style="text-transform: capitalize;">Call Response
                        Message</span></h6>
                <!-- <h6 *ngIf="currentList == 'track_driver_msg' || currentList == 'arrived_driver_msg'"><span
                        style="text-transform: capitalize;">Message</span>
                </h6> -->
                <h6
                    *ngIf="currentList != 'call_response' && currentList != 'track_driver_msg' && currentList != 'arrived_driver_msg'">
                    <span style="text-transform: capitalize;">{{currentList}}</span> message: {{currentIndex + 1}}
                </h6>
                <textarea [disabled]="isEdit" [(ngModel)]="currentMsg" placeholder="Message" rows="17"
                    class="form-control"></textarea>
            </div>
            <!-- <div *ngIf="show == 'tracking'" class="msg-box">
                <h5>Tracking message:</h5>
                <div *ngFor="let msg of tracking_msg; let i = index">
                    <h6 *ngIf="i==3">SMS getway message</h6>
                    <textarea [disabled]="isEdit" [value]="tracking_msg[i]" [(ngModel)]="tracking_msg[i]"
                        placeholder="Message" rows="4" class="form-control"></textarea>
                </div>
            </div>
            <div *ngIf="show == 'arrived'" class="msg-box">
                <h5>Arrived message:</h5>
                <div *ngFor="let msg of arrived_msg; let i = index">
                    <h6 *ngIf="i==3">SMS getway message</h6>
                    <textarea [disabled]="isEdit" [value]="arrived_msg[i]" [(ngModel)]="arrived_msg[i]"
                        placeholder="Message" rows="4" class="form-control"></textarea>
                </div>
            </div>
            <div *ngIf="show == 'noshow'" class="msg-box">
                <h5>Noshow message:</h5>
                <div *ngFor="let msg of noshow_msg; let i = index">
                    <textarea [disabled]="isEdit" [value]="noshow_msg[i]" [(ngModel)]="noshow_msg[i]"
                        placeholder="Message" rows="4" class="form-control"></textarea>
                </div>
            </div> -->
        </div>

        <div style="text-align: center;">
            <button *ngIf="isEdit" (click)="editMessage()" class="btn btn-dark">Edit</button>
            <button
                *ngIf="!isEdit && currentList != 'call_response' && currentList != 'track_driver_msg' && currentList != 'arrived_driver_msg'"
                (click)="setMessage()" class="btn btn-dark">Save</button>
            <button *ngIf="!isEdit && currentList == 'call_response'" [disabled]="isCallResponse && !currentMsg"
                (click)="setCallRespose()" class="btn btn-dark">Save</button>
            <button *ngIf="!isEdit && currentList == 'track_driver_msg'"
                [disabled]="isTrackDriverMessage && !currentMsg" (click)="setTrackDriverMsg()"
                class="btn btn-dark">Save</button>
            <button *ngIf="!isEdit && currentList == 'arrived_driver_msg'"
                [disabled]="isArrivedDriverMessage && !currentMsg" (click)="setArrivedDriverMsg()"
                class="btn btn-dark">Save</button>
        </div>
    </div>
</div>

<div *ngIf="isLoad" class="loader row">
    <div class="text-center">
        <div class="loader1">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</div>