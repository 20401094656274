import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomMessagesComponent } from './custom-messages/custom-messages.component';
import { UsersListComponent } from './users-list/users-list.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ReviewComponent } from './review/review.component';
import { DriverReviewComponent } from './driver-review/driver-review.component';
import { BlacklistUserComponent } from './blacklist-user/blacklist-user.component';
import { DeviceListComponent } from './device-list/device-list.component'
import { AccountDevicesComponent } from './account-devices/account-devices.component'
import { LoginComponent } from './login/login.component'
import { AdminLoginComponent } from './admin-login/admin-login.component'
import { GreenapiQrCodeComponent } from './greenapi-qr-code/greenapi-qr-code.component'
import { BotMessagesComponent } from './bot-messages/bot-messages.component'
import { BotCustomValuesComponent } from './bot-custom-values/bot-custom-values.component'
import { BroadcastComponent } from './broadcast/broadcast.component'
import { MybroadcastComponent } from './mybroadcast/mybroadcast.component'
import { FbBspComponent } from './fb-bsp/fb-bsp.component'

export const Components: any[] = [
    DashboardComponent,
    CustomMessagesComponent,
    UsersListComponent,
    AccountDetailsComponent,
    AdminDashboardComponent,
    ReviewComponent,
    DriverReviewComponent,
    BlacklistUserComponent,
    DeviceListComponent,
    AccountDevicesComponent,
    LoginComponent,
    AdminLoginComponent,
    GreenapiQrCodeComponent,
    BotMessagesComponent,
    BotCustomValuesComponent,
    BroadcastComponent,
    MybroadcastComponent,
    FbBspComponent
]

export {
    DashboardComponent,
    CustomMessagesComponent,
    UsersListComponent,
    AccountDetailsComponent,
    AdminDashboardComponent,
    ReviewComponent,
    DriverReviewComponent,
    BlacklistUserComponent,
    DeviceListComponent,
    AccountDevicesComponent,
    LoginComponent,
    AdminLoginComponent,
    GreenapiQrCodeComponent,
    BotMessagesComponent,
    BotCustomValuesComponent,
    BroadcastComponent,
    MybroadcastComponent,
    FbBspComponent
}