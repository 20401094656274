import { Component, OnInit } from '@angular/core';
import { BotService, AdminService } from './../../services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  selected = '';
  currentUser: any;
  foundDevice: any;
  botActive = false;
  constructor(
    private AS: AdminService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('SMS'));
    if (!this.currentUser) {
      this.router.navigateByUrl('/sessions/signin');
    }
    this.AS.getUser(this.currentUser._id).subscribe(admin => {
      if (admin.wtsp_bot) {
        this.botActive = true;
      } else {
        this.botActive = false;
      }
      this.foundDevice = admin.wa_api.find(d => d.status && (d.wa_api_platform == 'chatapi' || d.wa_api_platform == 'maytapi' || d.wa_api_platform == 'greenapi'))
    })

    this.getCurrentUrl();
  }

  private getCurrentUrl() {
    const firstChild = this.activatedRoute.snapshot.firstChild
    if (firstChild != null) {
      if (firstChild['_routerState'].url == '/bot/blacklist-users') {
        this.selected = 'user'
      } else if (firstChild['_routerState'].url == '/bot/messages') {
        this.selected = 'msg'
      } else if (firstChild['_routerState'].url == '/bot/custom-messages') {
        this.selected = 'bot'
      } else if (firstChild['_routerState'].url == '/bot/custom-values') {
        this.selected = 'bot'
      } else if (firstChild['_routerState'].url == '/bot/my-broadcast') {
        this.selected = 'broadcast'
      }
      // else if (firstChild['_routerState'].url == '/bot/custom-values') {
      //   this.selected = 'set'
      // }
    } else {
      this.selected = 'msg'
      this.router.navigateByUrl('/bot/messages')
    }
  }

  // userDetails() {
  //   this.selected = 'user'
  //   this.router.navigateByUrl('/bot/users')
  // }

  openMessages() {
    this.selected = 'msg'
    this.router.navigateByUrl('/bot/messages')
  }

  openBotMessage() {
    this.selected = 'bot'
    this.router.navigateByUrl('/bot/custom-messages')
  }

  openBlacklist() {
    this.selected = 'user'
    this.router.navigateByUrl('/bot/blacklist-users')
  }

  openDevices() {
    this.selected = ''
    this.router.navigateByUrl(`/devices/${this.currentUser._id}`)
  }

  openHelp() {
    window.open("https://taximessages.com/smhelp")
  }

  openMyBroadcast() {
    this.selected = 'broadcast'
    this.router.navigateByUrl('/bot/my-broadcast')
  }
  logout() {
    localStorage.removeItem('SMS');
    localStorage.removeItem('SMSID');
    this.router.navigateByUrl('/sessions/signin');
  }

}
