import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BotService, AdminService } from './../../services';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component'
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  public userForm: FormGroup;
  private serviceModal: NgbModalRef;

  waApi = [];
  currentUser: any;
  currentApi: any;
  currentIndex: any;
  delayTime = 0;
  afterDelayTime = 0;
  isLoad = true;
  limit = 0;
  token = '';
  device_id = '';
  device_name = '';
  formEdit: boolean = false;
  apiEdit: boolean = false;
  isEdit: boolean = false;
  apiPlatform = 'waapi'
  currentAdmin: any;
  user_id = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _serviceModal: NgbModal,
    private fb: FormBuilder,
    private AS: AdminService,
    private BS: BotService
  ) { }

  ngOnInit() {
    this.isLoad = true;
    this.user_id = this.activatedRoute.params['value'].id
    this.AS.getCurrentAdminFromBack().subscribe(() => {
      this.currentAdmin = JSON.parse(localStorage.getItem('SMSADM'));
      if (!this.currentAdmin) {
        this.router.navigateByUrl('/sessions/admin/signin');
      }
      this.AS.getUser(this.user_id).subscribe(usr => {
        console.log(usr);
        this.currentUser = usr;
        let smsValue = 0;
        if (!usr.sms_getway2 && !usr.sms_via_app && !usr.sms_faretext) { smsValue = 1 }
        if (usr.sms_getway2) { smsValue = 2 }
        if (usr.sms_via_app) { smsValue = 3 }
        if (usr.sms_faretext) { smsValue = 4 }
        if (usr.sms_mobitext) { smsValue = 5 }
        if (usr.sms_voodoo) { smsValue = 6 }
        if (usr.sms_ejointech) { smsValue = 7 }
        this.userForm = this.fb.group({
          _id: [{ value: usr._id, disabled: !this.isEdit }, Validators.required],
          name: [{ value: usr.name, disabled: !this.isEdit }, Validators.required],
          user_name: [{ value: usr.user_name, disabled: !this.isEdit }, Validators.required],
          password: [{ value: usr.password, disabled: !this.isEdit }, Validators.required],
          response_api: [{ value: usr.response_api, disabled: !this.isEdit }],
          token: [{ value: usr.token, disabled: !this.isEdit }],
          icabbi_gateway: [{ value: usr.icabbi_gateway ? usr.icabbi_gateway : '', disabled: !this.isEdit }],
          getway: [{ value: usr.getway, disabled: !this.isEdit }],
          server_password: [{ value: usr.server_password, disabled: !this.isEdit }],
          faretext_getway: [{ value: usr.faretext_getway, disabled: !this.isEdit }],
          faretext_server_password: [{ value: usr.faretext_server_password, disabled: !this.isEdit }],
          faretext_sid: [{ value: usr.faretext_sid, disabled: !this.isEdit }],
          faretext_uid: [{ value: usr.faretext_uid, disabled: !this.isEdit }],
          msg_response: [{ value: usr.msg_response, disabled: !this.isEdit }],

          //mobitext
          mobitext_gateway: [{ value: usr.mobitext_gateway ? usr.mobitext_gateway : '', disabled: !this.isEdit }],
          mobitext_username: [{ value: usr.mobitext_username ? usr.mobitext_username : '', disabled: !this.isEdit }],
          mobitext_password: [{ value: usr.mobitext_password ? usr.mobitext_password : '', disabled: !this.isEdit }],
          mobitext_token: [{ value: usr.mobitext_token ? usr.mobitext_token : '', disabled: !this.isEdit }],

          //EjoinTech
          sms_ejointech: [{ value: usr.sms_ejointech ? usr.sms_ejointech : false, disabled: !this.isEdit }],
          sms_ejointech_url: [{ value: usr.sms_ejointech_url ? usr.sms_ejointech_url : '', disabled: !this.isEdit }],
          sms_ejointech_token: [{ value: usr.sms_ejointech_token ? usr.sms_ejointech_token : '', disabled: !this.isEdit }],

          otp_msg: [{ value: usr.otp_msg, disabled: !this.isEdit }],
          app_api: [{ value: usr.app_api, disabled: !this.isEdit }],
          app_key: [{ value: usr.app_key, disabled: !this.isEdit }],
          sms_api: [{ value: smsValue, disabled: !this.isEdit }],
        });
        if (usr.wa_api) { this.waApi = usr.wa_api }
        setTimeout(() => { this.isLoad = false; }, 1000)
      })
    })
  }

  editUserDetails() {
    this.isEdit = true;
    this.userForm.enable()
    this.userForm.controls._id.disable();
    // this.userForm.controls.name.enable();
    // this.userForm.controls.user_name.enable();
    // this.userForm.controls.password.enable();
    // this.userForm.controls.getway.enable();
    // this.userForm.controls.server_password.enable();
    // this.userForm.controls.faretext_getway.enable();
    // this.userForm.controls.faretext_server_password.enable();
    // this.userForm.controls.faretext_sid.enable();
    // this.userForm.controls.faretext_uid.enable();
    // this.userForm.controls.app_api.enable();
    // this.userForm.controls.app_key.enable();
    // this.userForm.controls.sms_api.enable();
    // this.userForm.controls.response_api.enable();
    // this.userForm.controls.msg_response.enable();
    // this.userForm.controls.sms_via_app.enable();
    // this.userForm.controls.sms_getway1.enable();
    // this.userForm.controls.sms_getway2.enable();
  }

  saveUserDetails() {
    this.isLoad = true;
    this.userForm.controls._id.enable();
    let obj = this.userForm.value;

    if (obj['sms_api'] == 1) { obj['sms_getway1'] = true; obj['sms_getway2'] = false; obj['sms_via_app'] = false; obj['sms_faretext'] = false; obj['sms_mobitext'] = false; obj['sms_voodoo'] = false; obj['sms_ejointech'] = false; }
    if (obj['sms_api'] == 2) { obj['sms_getway1'] = false; obj['sms_getway2'] = true; obj['sms_via_app'] = false; obj['sms_faretext'] = false; obj['sms_mobitext'] = false; obj['sms_voodoo'] = false; obj['sms_ejointech'] = false; }
    if (obj['sms_api'] == 3) { obj['sms_getway1'] = false; obj['sms_getway2'] = false; obj['sms_via_app'] = true; obj['sms_faretext'] = false; obj['sms_mobitext'] = false; obj['sms_voodoo'] = false; obj['sms_ejointech'] = false; }
    if (obj['sms_api'] == 4) { obj['sms_getway1'] = false; obj['sms_getway2'] = false; obj['sms_via_app'] = false; obj['sms_faretext'] = true; obj['sms_mobitext'] = false; obj['sms_voodoo'] = false; obj['sms_ejointech'] = false; }
    if (obj['sms_api'] == 5) { obj['sms_getway1'] = false; obj['sms_getway2'] = false; obj['sms_via_app'] = false; obj['sms_faretext'] = false; obj['sms_mobitext'] = true; obj['sms_voodoo'] = false; obj['sms_ejointech'] = false; }
    if (obj['sms_api'] == 6) { obj['sms_getway1'] = false; obj['sms_getway2'] = false; obj['sms_via_app'] = false; obj['sms_faretext'] = false; obj['sms_mobitext'] = false; obj['sms_voodoo'] = true; obj['sms_ejointech'] = false; }
    if (obj['sms_api'] == 7) { obj['sms_getway1'] = false; obj['sms_getway2'] = false; obj['sms_via_app'] = false; obj['sms_faretext'] = false; obj['sms_mobitext'] = false; obj['sms_voodoo'] = false; obj['sms_ejointech'] = true; }

    delete obj['sms_api']
    // console.log(obj);
    this.AS.updateUser(obj).subscribe(result => {
      this.isLoad = false;
      this.isEdit = false;
      this.userForm.disable()
    })
  }

  editApi(api, index) {
    this.currentApi = api;
    this.currentIndex = index;
    this.token = api.token;
    this.device_id = api.device_id;
    this.device_name = api.device_name;
    this.limit = api.limit;
    this.delayTime = api.delay_time;
    this.afterDelayTime = api.after_delay_time;
    this.apiPlatform = api.wa_api_platform;
    this.apiEdit = true;
    // console.log(api);

  }

  deleteApi(api, index) {
    this.serviceModal = this._serviceModal.open(ConfirmationModalComponent);
    this.serviceModal.componentInstance.msg = "Are you sure you want to delete this?"
    this.serviceModal.result.then((result) => {
      this.waApi.splice(index, 1);
      let obj = {
        _id: this.currentUser._id,
        wa_api: this.waApi
      }
      this.AS.updateUser(obj).subscribe(result => {
        this.currentApi = null;
        this.currentIndex = 0;
        this.token = ''
        this.device_id = '';
        this.device_name = '';
        this.delayTime = 0;
        this.afterDelayTime = 0;
        this.limit = 0;
        this.apiEdit = false;
        this.apiPlatform = 'waapi';
      })
    }, (reason) => {
      // console.log('cross');
    })
  }

  botActivation(e) {
    this.isLoad = true;
    let obj = {
      _id: this.currentUser._id,
      wtsp_bot: e.target.checked
    }

    this.AS.botActivation(obj).subscribe(result => {
      this.ngOnInit();
    })
  }

  apiActivation(e, api, index) {

    this.AS.getUser(this.user_id).subscribe(usr => {
      if (usr.wa_api) { this.waApi = usr.wa_api }
      let apiObj = usr.wa_api.find(apil => apil._id == api._id)
      apiObj['status'] = e.target.checked
      this.waApi[index] = apiObj;
      let obj = {
        _id: this.currentUser._id,
        wa_api: this.waApi
      }
      // console.log(obj);

      this.AS.updateUser(obj).subscribe(result => {
        this.currentApi = null;
        this.currentIndex = 0;
        this.token = ''
        this.device_id = '';
        this.device_name = '';
        this.delayTime = 0;
        this.afterDelayTime = 0;
        this.limit = 0;
        this.apiEdit = false;
        this.apiPlatform = 'waapi';
      })
    })

  }

  addApi() {
    this.AS.getUser(this.user_id).subscribe(usr => {
      if (usr.wa_api) { this.waApi = usr.wa_api }

      let apiObj = {
        token: this.token,
        active: true,
        device_id: this.device_id,
        status: false,
        delay_time: this.delayTime,
        after_delay_time: this.afterDelayTime,
        first_day: new Date(),
        device_name: this.device_name,
        limit: this.limit,
        calls: 0,
        total_calls: 0,
        total_last_calls: 0,
        last_calls: 0,
        wa_api_platform: this.apiPlatform
      }

      this.waApi.push(apiObj)
      let obj = {
        _id: this.currentUser._id,
        wa_api: this.waApi
      }

      this.AS.updateUser(obj).subscribe(result => {
        this.waApi = result.wa_api;
        this.currentApi = null;
        this.currentIndex = 0;
        this.token = ''
        this.device_id = '';
        this.device_name = '';
        this.delayTime = 0;
        this.afterDelayTime = 0;
        this.limit = 0;
        this.apiEdit = false;
        this.apiPlatform = 'waapi';
      })
    })
  }

  updateApi() {
    if (!this.delayTime) { this.delayTime = 0; }
    if (!this.afterDelayTime) { this.afterDelayTime = 0; }
    if (!this.limit) { this.limit = 0; }
    this.AS.getUser(this.user_id).subscribe(usr => {
      if (usr.wa_api) { this.waApi = usr.wa_api }
      this.currentApi = usr.wa_api.find(apil => apil._id == this.currentApi._id)
      let apiObj = this.currentApi;
      apiObj['token'] = this.token
      apiObj['device_id'] = this.device_id
      apiObj['device_name'] = this.device_name
      apiObj['limit'] = this.limit
      apiObj['delay_time'] = this.delayTime
      apiObj['after_delay_time'] = this.afterDelayTime
      apiObj['wa_api_platform'] = this.apiPlatform

      this.waApi[this.currentIndex] = apiObj;
      let obj = {
        _id: this.currentUser._id,
        wa_api: this.waApi
      }

      this.AS.updateUser(obj).subscribe(result => {
        this.currentApi = null;
        this.currentIndex = 0;
        this.token = ''
        this.device_id = '';
        this.device_name = '';
        this.delayTime = 0;
        this.afterDelayTime = 0
        this.limit = 0;
        this.apiEdit = false;
        this.apiPlatform = 'waapi';
      })

    })
  }

  updateUser() {

  }
}
