<div class="modal-header" style="background-image: linear-gradient(to right, #009688, #02d5c1);">
    <h2 class="modal-title" id="modal-title" style="color: #fff;margin: 0px;">Confirmation</h2>
    <button type="button" style="color: #fff; opacity: 1;" class="close" aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding: 3rem 5rem">
    <h5 style="text-align: center;">{{msg}}</h5>
</div>
<div *ngIf="msg != 'Broadcast initiated.'" class="modal-footer" style="justify-content: center">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('true')">
        Cancel
    </button>
    <button type="button" class="btn btn-outline-success" (click)="modal.close('true')">Confirm</button>
</div>
<div *ngIf="msg == 'Broadcast initiated.'" class="modal-footer" style="justify-content: center">
    <button type="button" class="btn btn-outline-success" (click)="modal.close('true')">Close</button>
</div>