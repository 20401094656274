<div class="navbar">
    <div class="row" style="margin: 0px; width: 100%;">
        <div class="col-md-6" style="display: flex; flex-wrap: nowrap;">
            <div>
                <a style="color: #fff;display: flex;height: 4rem;padding: 5px;"><img
                        src="../../../assets/images/logo.png"></a>
            </div>
        </div>
        <div class="col-md-6" style="display: flex; align-items: center; justify-content: flex-end;">
            <div class="navbar-item">
                <a (click)="openMessages()" [ngStyle]="{color : selected == 'msg' ? '#00d099' : '#009688'}">Messages</a>
            </div>
            <div class="navbar-item">
                <a (click)="openBlacklist()"
                    [ngStyle]="{color : selected == 'user' ? '#00d099' : '#009688'}">Blacklist</a>
            </div>
            <div *ngIf="botActive" class="navbar-item">
                <a (click)="openBotMessage()" [ngStyle]="{color : selected == 'bot' ? '#00d099' : '#009688'}">Bot
                    Messages</a>
            </div>
            <!-- <div *ngIf="botActive" class="navbar-item">
                <a (click)="openBroadcast()" [ngStyle]="{color : selected == 'bot' ? '#00d099' : '#009688'}">Bot
                    Messages</a>
            </div> -->
            <!-- <div class="navbar-item" *ngIf="currentUser._id == '5fb6e975e6b80b6fa95fb04c'|| currentUser._id == '5fa33297c9ec66867188999f'"> -->
            <div class="navbar-item">
                <a (click)="openMyBroadcast()" [ngStyle]="{color : selected == 'broadcast' ? '#00d099' : '#009688'}">
                    Broadcast</a>
            </div>
            <div *ngIf="foundDevice" class="navbar-item">
                <a (click)="openDevices()" [ngStyle]="{color : selected == 'dev' ? '#00d099' : '#009688'}">Devices</a>
            </div>
            <div *ngIf="foundDevice" class="navbar-item">
                <a (click)="openHelp()" [ngStyle]="{color : selected == 'help' ? '#00d099' : '#009688'}">Help</a>
            </div>
            <div class="navbar-item">
                <a (click)="logout()">Logout</a>
            </div>
        </div>
    </div>
</div>
<div style="margin-top: 5rem;">
    <router-outlet></router-outlet>
</div>